import { React } from "react";
import sport from "../img/sport.png"; // Importing the audio
import sport1 from "../img/sport1.png";
import sport2 from "../img/sport2.png";
import { Footer } from "./Footer";
import { Helmet } from "react-helmet-async"; // Import Helmet
import "font-awesome/css/font-awesome.min.css"; // Ensure you import Font Awesome CSS

const Sport = () => {
  return (
    <>
    <Helmet>
        <title>Arc Solutions || Sport Portal</title>
        <meta name="description" content="This is a description of the page content." />
        <meta name="keywords" content="React, carousel, example, meta tags" />
        <meta name="author" content="Your Name or Company" />
        <meta property="og:title" content="Your Page Title" />
        <meta property="og:description" content="This is a description of the page content." />
        <meta property="og:image" content="URL_to_your_image" />
        <meta property="og:url" content="Your_page_URL" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Your Page Title" />
        <meta name="twitter:description" content="This is a description of the page content." />
        <meta name="twitter:image" content="URL_to_your_image" />
      </Helmet>
      {/* Banner Job Portal Start */}
      <img
        className="d-block w-100"
        src={sport}
        alt="Job_Portal1"
        style={{ height: "100%", objectFit: "cover" }}
      />
      {/* Banner Job Portal End */}
    
      <div class="bg-white py-6 sm:py-8 lg:py-12">
        <div class="mx-auto max-w-screen-xl px-4 md:px-8">
          <div class="grid gap-8 md:grid-cols-2 lg:gap-12">
            <div>
              <div class="h-64 overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-auto">
                <img
                  src={sport1}
                  style={{ height: "420px", objectFit: "cover" }}
                  loading="lazy"
                  alt="Photo by Martin Sanchez"
                  class="h-full w-full object-cover object-center"
                />
              </div>
            </div>

            <div class="md:pt-8">
              <p class="text-center font-bold text-indigo-500 md:text-left">
                Our Sport Portal
              </p>

              <h1 class="mb-4 text-center text-2xl font-bold text-gray-800 sm:text-3xl md:mb-6 md:text-left">
                Welcome to Sport Portal
              </h1>

              <p class="mb-6 text-gray-500 sm:text-lg md:mb-8">
                Arc Solution's Sports Portal is a premier platform providing real-time sports news, live scores, and in-depth analysis across various sports. Utilizing advanced technologies like Python for data processing and React for seamless user interfaces, the portal ensures an engaging and interactive experience. Whether you're a fan, analyst, or player, Arc Solution's Sports Portal offers comprehensive coverage, personalized alerts, and exclusive content to keep you informed and connected to the sports world.
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>



            {/* Online News  Portal Start */}
            <div className="container mt-5">
        <div className="row">
          <div className="col-md-7">
            <br />
            <br /> <br />
            <br />
            <h3 className="font-bold">Online Sports Portal</h3>
            <p style={{ fontSize: "20px" }}>
            Interested in Sports Portal design? Want to create an engaging sports platform or upgrade your existing sports blog to be more automated? We specialize in developing user-friendly and easy-to-navigate sports portals that help you manage sports content, users, live scores, promotions, and much more with a powerful content management system.
            </p>
          </div>
          <div className="col-md-5">

            <img src={sport2} className="" />
          </div>
          <h3 className="font-bold">General Features of Our Sports Portal:</h3>
          <div class="flex items-center gap-2 text-black">
          <i class="fa fa-check-square-o text-indigo-700" aria-hidden="true"></i>

            <span class="text-gray-600 text-black sm:text-lg">
            Free 1st year web hosting and domain name!
            </span>
          </div>
          <div class="flex items-center gap-2 text-black">
          <i class="fa fa-check-square-o text-indigo-700" aria-hidden="true"></i>

            <span class="text-gray-600 text-black">
            Customization of Unique Design for your sports portal

            </span>
          </div>
          <div class="flex items-center gap-2 text-black">
          <i class="fa fa-check-square-o text-indigo-700" aria-hidden="true"></i>

            <span class="text-gray-600 text-black">Integration with Social Networking Tools (Facebook, Twitter, Google+, Pinterest)</span>
          </div>
          <div class="flex items-center gap-2 text-black">
          <i class="fa fa-check-square-o text-indigo-700" aria-hidden="true"></i>

            <span class="text-gray-600 text-black">
            Content Management Allows you to manage your sports news, articles, and categories easily.
            </span>
          </div>
          <div class="flex items-center gap-2 text-black">
          <i class="fa fa-check-square-o text-indigo-700" aria-hidden="true"></i>

            <span class="text-gray-600 text-black">
            User Management Able to manage your users or group them accordingly on your platform.
            </span>
          </div>
          <div class="flex items-center gap-2 text-black">
          <i class="fa fa-check-square-o text-indigo-700" aria-hidden="true"></i>

            <span class="text-gray-600 text-black">
            Promotion Tools Allows you to create special promotions, up-sell, cross-sell, set promotional items, and categories, and much more.
            </span>
          </div>
          <div class="flex items-center gap-2 text-black">
          <i class="fa fa-check-square-o text-indigo-700" aria-hidden="true"></i>

            <span class="text-gray-600 text-black">
            Live Scores Integrate live score updates from major sports events.
            </span>
          </div>
          <div class="flex items-center gap-2 text-black">
          <i class="fa fa-check-square-o text-indigo-700" aria-hidden="true"></i>

            <span class="text-gray-600 text-black">
            Payment Gateway Support For premium content or membership subscriptions via PayPal, Bank Transfer, and others.
            </span>
          </div>
          <div class="flex items-center gap-2 text-black">
          <i class="fa fa-check-square-o text-indigo-700" aria-hidden="true"></i>
            <span class="text-gray-600 text-black">Report Management Provides detailed analytic reports such as most viewed content, best-performing articles, top search terms, etc., to help you analyze and improve user engagement.</span>
          </div>
          <div class="flex items-center gap-2 text-black">
          <i class="fa fa-check-square-o text-indigo-700" aria-hidden="true"></i>

            <span class="text-gray-600 text-black">
            Mobile Friendly Your users can access your sports portal easily via mobile devices such as iPhone, Android, or iPad without any issues!
            </span>
          </div>
       
        </div>
      </div>
      {/* Online Job Portal End */}

<br/><br/>
       {/* Connect With App Start */}
       <section class="pt-10 pb-8 overflow-hidden bg-gray-100 sm:pt-16 lg:pt-24">
        <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div class="max-w-2xl mx-auto text-center">
            <h2 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">
              Connect with all apps
            </h2>
          </div>
        </div>

        <img
          class="w-full min-w-full mx-auto mt-12 scale-150 max-w-7xl lg:min-w-0 lg:mt-0 lg:scale-100"
          src="https://cdn.rareblocks.xyz/collection/celebration/images/integration/1/services-icons.png"
          alt=""
        />
      </section>
      {/* Connect With App End */}

      <Footer />
    </>
  );
};

export default Sport;
