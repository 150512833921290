import { React } from "react";
import website from "../img/website1.jpg";
import { Footer } from "./Footer";
import { Helmet } from "react-helmet-async"; // Import Helmet
// images for services
import website1 from "../img/website.png";
import google2 from "../img/google2.jpg";

function Website_traffic() {
  return (
    <>
     <Helmet>
        <title>Arc Solutions || PPC </title>
        <meta name="description" content="Learn more about Arc Solutions, a leading IT Solution provider." />
        <meta name="keywords" content="Arc Solutions, IT services, Web Development, ERP Solutions, Software Development" />
        <meta name="author" content="Arc Solutions" />
      </Helmet>
      {/* Banner Job Portal Start */}
      <img
        className="d-block w-100"
        src={website}
        alt="Job_Portal1"
        style={{ height: "100%", objectFit: "cover" }}
      />
      {/* Banner Job Portal End */}

      {/* Google Promotion for Arc Solutions Start */}
      <div class="bg-white py-6 sm:py-8 lg:py-12">
        <div class="mx-auto max-w-screen-xl px-4 md:px-8">
          <div class="grid gap-8 md:grid-cols-2 lg:gap-12">
            <div>
              <div class="h-64 overflow-hidden rounded-lg  shadow-lg md:h-auto">
                <img src={website1} alt="Photo by Martin Sanchez" />
              </div>
            </div>

            <div class="md:pt-8 mt-1">
              <p class="text-center font-bold text-indigo-500 md:text-left">
                Arc solutions
              </p>

              <h1 class="mb-4 text-center text-2xl font-bold text-gray-800 sm:text-3xl md:mb-6 md:text-left">
                Website Traffic Promotion
              </h1>

              <p class="mb-6 text-dark sm:text-lg md:mb-8">
                Arc Solutions is a premier website traffic promotion company
                dedicated to helping businesses achieve their online visibility
                and growth goals. With a comprehensive suite of digital
                marketing strategies, we ensure your website attracts and
                retains quality traffic. Our team of experts leverages the
                latest SEO techniques, content marketing, and social media
                campaigns to drive organic and paid traffic to your site. At Arc
                Solutions, we understand the importance of targeted traffic and
                work relentlessly to optimize your website's performance. Our
                tailored approach guarantees enhanced user engagement, higher
                conversion rates, and sustained online presence, empowering your
                business to thrive in the digital landscape.
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Google Promotion for Arc Solutions End */}
      

      {/* Instructions Google Promotion Start */}
      <div class="bg-white py-6 sm:py-8 lg:py-12">
        <div class="mx-auto max-w-screen-xl px-4 md:px-8">
          <div class="grid gap-8 md:grid-cols-2 lg:gap-12">
            <div class="md:pt-8">
              <h1 class="mb-4 text-center text-2xl font-bold text-gray-800 sm:text-3xl md:mb-6 md:text-left">
                Instructions:
              </h1>

              <div class="flex items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 shrink-0 text-indigo-500"
                  fill="none"
                  viewBox="0 0 16 16"
                >
                  <circle
                    cx="8"
                    cy="8"
                    r="8"
                    fill="currentColor"
                    class="text-gray-300 text-indigo-700"
                  />
                  <circle
                    cx="8"
                    cy="8"
                    r="3"
                    fill="currentColor"
                    class="text-gray-500 text-indigo-700"
                  />
                </svg>

                <span class="text-gray-600 text-black">
                  Keyword Research: Identify and target high-traffic keywords
                  relevant to your industry.
                </span>
              </div>
              <br />
              <div class="flex items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 shrink-0"
                  fill="none"
                  viewBox="0 0 16 16"
                >
                  <circle
                    cx="8"
                    cy="8"
                    r="8"
                    fill="currentColor"
                    class="text-gray-300 text-indigo-700"
                  />
                  <circle
                    cx="8"
                    cy="8"
                    r="3"
                    fill="currentColor"
                    class="text-gray-500 text-indigo-700"
                  />
                </svg>

                <span class="text-gray-600 text-black">
                  Ad Creation: Develop compelling and relevant ads to attract
                  potential customers.
                </span>
              </div>
              <br />
              <div class="flex items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 shrink-0"
                  fill="none"
                  viewBox="0 0 16 16"
                >
                  <circle
                    cx="8"
                    cy="8"
                    r="8"
                    fill="currentColor"
                    class="text-gray-300 text-indigo-700"
                  />
                  <circle
                    cx="8"
                    cy="8"
                    r="3"
                    fill="currentColor"
                    class="text-gray-500 text-indigo-700"
                  />
                </svg>

                <span class="text-gray-600 text-black">
                  Landing Page Optimization: Ensure landing pages are optimized
                  for conversions and user experience.
                </span>
              </div>
              <br />
              <div class="flex items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 shrink-0"
                  fill="none"
                  viewBox="0 0 16 16"
                >
                  <circle
                    cx="8"
                    cy="8"
                    r="8"
                    fill="currentColor"
                    class="text-gray-300 text-indigo-700"
                  />
                  <circle
                    cx="8"
                    cy="8"
                    r="3"
                    fill="currentColor"
                    class="text-gray-500 text-indigo-700"
                  />
                </svg>

                <span class="text-gray-600 text-black">
                  Bid Management: Use smart bidding strategies to maximize ROI
                  on your ad spend.
                </span>
              </div>
              <br />
              <div class="flex items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 shrink-0"
                  fill="none"
                  viewBox="0 0 16 16"
                >
                  <circle
                    cx="8"
                    cy="8"
                    r="8"
                    fill="currentColor"
                    class="text-gray-300 text-indigo-700"
                  />
                  <circle
                    cx="8"
                    cy="8"
                    r="3"
                    fill="currentColor"
                    class="text-gray-500 text-indigo-700"
                  />
                </svg>

                <span class="text-gray-600 text-black">
                  Audience Targeting: Utilize demographic, geographic, and
                  interest-based targeting to reach the right audience.
                </span>
              </div>
              <br />
              <div class="flex items-center gap-2 text-black">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 shrink-0"
                  fill="none"
                  viewBox="0 0 16 16"
                >
                  <circle
                    cx="8"
                    cy="8"
                    r="8"
                    fill="currentColor"
                    class="text-gray-300 text-indigo-700"
                  />
                  <circle
                    cx="8"
                    cy="8"
                    r="3"
                    fill="currentColor"
                    class="text-gray-500 text-indigo-700"
                  />
                </svg>

                <span class="text-gray-600 text-black">
                  Performance Tracking: Monitor and analyze campaign performance
                  using Google Analytics.
                </span>
              </div>
              <br />
              <div class="flex items-center gap-2 text-black">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 shrink-0"
                  fill="none"
                  viewBox="0 0 16 16"
                >
                  <circle
                    cx="8"
                    cy="8"
                    r="8"
                    fill="currentColor"
                    class="text-gray-300 text-indigo-700"
                  />
                  <circle
                    cx="8"
                    cy="8"
                    r="3"
                    fill="currentColor"
                    class="text-gray-500 text-indigo-700"
                  />
                </svg>

                <span class="text-gray-600 text-black">
                  Ad Extensions: Implement ad extensions to enhance ad
                  visibility and provide additional information.
                </span>
              </div>
              <br />
              <div class="flex items-center gap-2 text-black">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 shrink-0"
                  fill="none"
                  viewBox="0 0 16 16"
                >
                  <circle
                    cx="8"
                    cy="8"
                    r="8"
                    fill="currentColor"
                    class="text-gray-300 text-indigo-700"
                  />
                  <circle
                    cx="8"
                    cy="8"
                    r="3"
                    fill="currentColor"
                    class="text-gray-500 text-indigo-700"
                  />
                </svg>

                <span class="text-gray-600 text-black">
                  A/B Testing: Continuously test different ad variations to find
                  the most effective ones.
                </span>
              </div>
              <br />
              <div class="flex items-center gap-2 text-black">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 shrink-0"
                  fill="none"
                  viewBox="0 0 16 16"
                >
                  <circle
                    cx="8"
                    cy="8"
                    r="8"
                    fill="currentColor"
                    class="text-gray-300 text-indigo-700"
                  />
                  <circle
                    cx="8"
                    cy="8"
                    r="3"
                    fill="currentColor"
                    class="text-gray-500 text-indigo-700"
                  />
                </svg>

                <span class="text-gray-600 text-black">
                  SEO Integration: Combine paid ads with strong SEO practices
                  for a holistic approach.
                </span>
              </div>
              <br />
              <div class="flex items-center gap-2 text-black">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 shrink-0 "
                  fill="none"
                  viewBox="0 0 16 16"
                >
                  <circle
                    cx="8"
                    cy="8"
                    r="8"
                    fill="currentColor"
                    class="text-gray-300 text-indigo-700"
                  />
                  <circle
                    cx="8"
                    cy="8"
                    r="3"
                    fill="currentColor"
                    class="text-gray-500 text-indigo-700"
                  />
                </svg>

                <span class="text-gray-600 text-black">
                  Regular Reporting: Provide detailed performance reports and
                  insights to track progress and adjust strategies accordingly.
                </span>
              </div>
            </div>
            <div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />

              <div className="h-64 overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-auto hover:shadow-xl">
                <img
                  src={google2}
                  style={{ height: "420px", objectFit: "cover" }}
                  loading="lazy"
                  alt="Photo by Martin Sanchez"
                  className="h-full w-full object-cover object-center transform scale-100 hover:scale-105 transition duration-300"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Instructions Google Promotion End */}

      {/* Connect With App Start */}
      

      <Footer />
    </>
  );
}

export default Website_traffic;
