import { React } from "react";
import ecommerce from "../img/Ecommerce.mp4"; // Importing the audio
import ecommerce1 from "../img/Ecommerce3.png";
import { Helmet } from "react-helmet-async"; // Import Helmet
import service from "../img/services-icons.png";
import { Footer } from "./Footer";
import "font-awesome/css/font-awesome.min.css"; // Ensure you import Font Awesome CSS

function E_commerce() {
  return (
    <div>
      <Helmet>
        <title>Arc Solutions || E-commerce</title>
        <meta name="description" content="This is a description of the page content." />
        <meta name="keywords" content="React, carousel, example, meta tags" />
        <meta name="author" content="Your Name or Company" />
        <meta property="og:title" content="Your Page Title" />
        <meta property="og:description" content="This is a description of the page content." />
        <meta property="og:image" content="URL_to_your_image" />
        <meta property="og:url" content="Your_page_URL" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Your Page Title" />
        <meta name="twitter:description" content="This is a description of the page content." />
        <meta name="twitter:image" content="URL_to_your_image" />
      </Helmet>
      {/* Banner Video Job Portal Start */}
      <video className="d-block w-100" autoPlay loop muted>
        <source src={ecommerce} type="audio/mp3" style={{ height: "80px" }} />
        Your browser does not support the audio element.
      </video>
      {/* Banner Job Portal End */}

      <div class="bg-white py-6 sm:py-8 lg:py-12">
        <div class="mx-auto max-w-screen-xl px-4 md:px-8">
          <div class="grid gap-8 md:grid-cols-2 lg:gap-12">
            <div>
              <div class="h-64 overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-auto">
                <img
                  src={ecommerce1}
                  alt="Photo by Martin Sanchez"
                  class="h-full w-full object-cover object-center"
                />
              </div>
            </div>

            <div class="md:pt-8">
              <br />
              <br />
              <h1 class="mb-4 text-center text-2xl font-bold text-indigo-500 sm:text-3xl md:mb-6 md:text-left">
                Welcome to E-Commerce
              </h1>
              <p class="mb-6 text-black sm:text-lg md:mb-8">
                Interested in e-Commerce/Online Shopping website design? Want to
                start an online shop or upgrade your blogshop to be more
                automated? We are capable of creating user-friendly and easy to
                use e-Commerce website that help you manage your e-Commerce
                inventories, customers, shipping, promotion, sales and many more
                with a powerful Shopping Cart Engine system.
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Online News  Portal Start */}
      <div className="container mt-5">
        <div className="row">
          <h3 className="font-bold">
            General features of our eCommerce Website :
          </h3>
          <div class="flex items-center gap-2 text-black">
            <i class="fa fa-check-square-o text-indigo-700" aria-hidden="true"></i>

            <span class=" text-black">
              Free 1st year web hosting and domain name!
            </span>
          </div>
          <div class="flex items-center gap-2 text-black">
            <i class="fa fa-check-square-o text-blue-900" aria-hidden="true"></i>

            <span class=" text-black">
              Customization of Unique Design for your eCommerce website
            </span>
          </div>
          <div class="flex items-center gap-2 text-black">
          <i class="fa fa-check-square-o text-indigo-700" aria-hidden="true"></i>

            <span class=" text-black">
              {" "}
              Integration with Social Networking Tool (Facebook , Twitter,
              Google +, Pinterest).
            </span>
          </div>
          <div class="flex items-center gap-2 text-black">
          <i class="fa fa-check-square-o text-indigo-700" aria-hidden="true"></i>

            <span class=" text-black">
              Catalog Management Allows you to manage your products and
              categories easily.
            </span>
          </div>
          <div class="flex items-center gap-2 text-black">
          <i class="fa fa-check-square-o text-indigo-700" aria-hidden="true"></i>

            <span class=" text-black">
              Customer Management Able to manage your customers or group them
              accordingly in your online store.
            </span>
          </div>
          <div class="flex items-center gap-2 text-black">
          <i class="fa fa-check-square-o text-indigo-700" aria-hidden="true"></i>

            <span class=" text-black">
              Promotion Tools Allows you to create coupon code, up sell, cross
              sell, set promotional items and categories and many more.
            </span>
          </div>
          <div class="flex items-center gap-2 text-black">
          <i class="fa fa-check-square-o text-indigo-700" aria-hidden="true"></i>

            <span class=" text-black">
              Shipping Tools Set your own shipping rates base on Singpost, Fedex
              & others.
            </span>
          </div>
          <div class="flex items-center gap-2 text-black">
          <i class="fa fa-check-square-o text-indigo-700" aria-hidden="true"></i>

            <span class=" text-black">
              Payment Gateway Support Credit/debit Card via PayPal Payment, Bank
              Transfer & others.
            </span>
          </div>
          <div class="flex items-center gap-2 text-black">
          <i class="fa fa-check-square-o text-indigo-700" aria-hidden="true"></i>

            <span class=" text-black">
              Report Management Shows you all kind of analytic reports such as
              most viewed product, best seller product, top search term etc to
              allow you to analyze and improve your sales.
            </span>
          </div>
          <div class="flex items-center gap-2 text-black">
          <i class="fa fa-check-square-o text-indigo-700" aria-hidden="true"></i>

            <span class=" text-black">
              Mobile Friendly Your customer can purchase your products easily
              via mobile devices such as iPhone, Android or iPad without any
              problem!
            </span>
          </div>
          <div class="flex items-center gap-2 text-black">
          <i class="fa fa-check-square-o text-indigo-700" aria-hidden="true"></i>

            <span class=" text-black">Latest news update</span>
          </div>
        </div>
      </div>
      {/* Online Job Portal End */}

      <br />

      {/* Connect With App Start */}
      <section class="pt-10 pb-8 overflow-hidden bg-gray-100 sm:pt-16 lg:pt-24">
        <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div class="max-w-2xl mx-auto text-center">
            <h2 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">
              Connect with all apps
            </h2>
          </div>
        </div>

        <img
                  src={service}
                  alt="Photo by Martin Sanchez"
                  class="h-full w-full object-cover object-center"
                />
      </section>
      {/* Connect With App End */}
      <script></script>
      <Footer />
    </div>
  );
}

export default E_commerce;
