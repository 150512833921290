import React from "react";
import { Footer } from "./Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet-async"; // Import Helmet
import {
  faGraduationCap,
  faHeartbeat,
  faDumbbell,
  faShoppingCart,
  faLaptopCode,
  faShoppingBag,
  faBasketballBall,
  faBriefcase,
} from "@fortawesome/free-solid-svg-icons";
import "./Android_developer.css";
import android from "../img/android.png";
import team from "../img/teamwork.png";
import team2 from "../img/trust.png";
import team3 from "../img/group.png";
import team4 from "../img/man.png";
import team5 from "../img/circular-economy.png";
import team6 from "../img/pencil.png";
import multi from "../img/multi1.png";
import team7 from "../img/education.png";

function AndroidDeveloper() {
  return (
    
    <div>
      <Helmet>
        <title>Arc Solutions || Andriod Developer</title>
        <meta name="description" content="Learn more about Arc Solutions, a leading IT Solution provider." />
        <meta name="keywords" content="Arc Solutions, IT services, Web Development, ERP Solutions, Software Development" />
        <meta name="author" content="Arc Solutions" />
      </Helmet>
      <div className="main-home container-fluid">
        <div className="row">
          <div className="col-md-6 col-sm-12 col-xs-12">
            <section className="main-home">
              <div className="main-text">
                <h1>
                  Custom Android App <br /> Development <br />
                  Company
                </h1>
                <p>
                  We specialize in Android mobile app development for startups,
                  small businesses to enterprises. Our Android app developers
                  have many years of expertise creating bespoke, stable, fully
                  responsive, & futuristic mobile apps for many Android devices.
                  We have delivered many custom Android applications to our
                  clients, for which we are so proud! Try us now to get your
                  Android App developed with us.
                </p>
              </div>
            </section>
          </div>
          <div className="col-md-6">
            <section className="main-home">
              <img
                className="d-block w-100"
                src={android}
                alt="Banner"
                style={{ height: "100%", objectFit: "cover" }}
              />
            </section>
          </div>
        </div>
      </div>

      <section
        className="py-10 from-fuchsia-600 to-blue-600 sm:py-16 lg:py-24"
        style={{ backgroundColor: "#e6efff" }}
      >
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="mx-auto text-left sm:text-center">
            <h2 className="text-3xl font-bold leading-tight text-dark sm:text-4xl lg:text-5xl lg:leading-tight">
              Our Android Mobile App Development Services
            </h2>
            <p className="mt-4 text-xl text-dark">
              In the mobile app development industry, we have over 18+ years of
              experience as the best custom android application development
              company. We are known for developing mobile apps with sturdy code,
              a user-friendly design. Our dedicated Android app designers and
              developers use the most up-to-date Android technology to create
              cutting-edge Android mobile app development solutions. That
              provides Android consumers with attractive designs and excellent
              user experiences.
            </p>
          </div>

          <div
            className="bg-white py-6 sm:py-8 lg:py-12"
            style={{ paddingLeft: "5rem", paddingRight: "5rem" }}
          >
            <div className="mx-auto max-w-screen-xl px-4 md:px-8">
              <div className="grid gap-4 md:grid-cols-2 md:gap-8">
                <div className="flex flex-col items-center gap-4 rounded-lg bg-indigo-500 px-8 py-6 md:gap-6 hover:bg-indigo-600 transition duration-300 transform hover:scale-105">
                  <div className="max-w-md text-center text-white lg:text-lg">
                    <h3>Android App Development Solutions</h3>
                    <p className="text-justify">
                      Our skilled Android app developers will create an Android
                      app tailored to your company's demands.
                    </p>
                  </div>
                </div>

                <div className="flex flex-col items-center gap-4 rounded-lg bg-indigo-500 px-8 py-6 md:gap-6 hover:bg-indigo-600 transition duration-300 transform hover:scale-105">
                  <div className="max-w-md text-center text-white lg:text-lg">
                    <h3>Full Stack Android Developer</h3>
                    <p className="text-justify">
                      With the help of our professionals and the most up-to-date
                      technologies, we provide full-cycle Android app
                      development services.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="mx-auto max-w-screen-xl px-4 md:px-8">
              <div className="grid gap-4 md:grid-cols-2 md:gap-8">
                <div className="flex flex-col items-center gap-4 rounded-lg bg-indigo-500 px-8 py-6 md:gap-6 hover:bg-indigo-600 transition duration-300 transform hover:scale-105">
                  <div className="max-w-md text-center text-white lg:text-lg">
                    <h3>Web-based Android Applications</h3>
                    <p className="text-justify">
                      We are skilled in creating Web-based Android apps that are
                      simple to set up, operate, and rely on.
                    </p>
                  </div>
                </div>

                <div className="flex flex-col items-center gap-4 rounded-lg bg-indigo-500 px-8 py-6 md:gap-6 hover:bg-indigo-600 transition duration-300 transform hover:scale-105">
                  <div className="max-w-md text-center text-white lg:text-lg">
                    <h3>Android App Development Consultation</h3>
                    <p className="text-justify">
                      We can help you get started with Android and provide total
                      development consultation.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="mx-auto max-w-screen-xl px-4 md:px-8">
              <div className="grid gap-4 md:grid-cols-2 md:gap-8">
                <div className="flex flex-col items-center gap-4 rounded-lg bg-indigo-500 px-8 py-6 md:gap-6 hover:bg-indigo-600 transition duration-300 transform hover:scale-105">
                  <div className="max-w-md text-center text-white lg:text-lg">
                    <h3>Android UI/UX Design</h3>
                    <p className="text-justify">
                      Our UI/UX designers are skilled at building dynamic UI/UX
                      that accurately represents your brand.
                    </p>
                  </div>
                </div>

                <div className="flex flex-col items-center gap-4 rounded-lg bg-indigo-500 px-8 py-6 md:gap-6 hover:bg-indigo-600 transition duration-300 transform hover:scale-105">
                  <div className="max-w-md text-center text-white lg:text-lg">
                    <h3>Multi-platform Deployment</h3>
                    <p className="text-justify">
                      Any app may be seamlessly integrated and deployed across
                      numerous platforms with the help of our skilled Android
                      developers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="mx-auto max-w-screen-xl px-4 md:px-8">
              <div className="grid gap-4 md:grid-cols-2 md:gap-8">
                <div className="flex flex-col items-center gap-4 rounded-lg bg-indigo-500 px-8 py-6 md:gap-6 hover:bg-indigo-600 transition duration-300 transform hover:scale-105">
                  <div className="max-w-md text-center text-white lg:text-lg">
                    <h3>Android App Testing</h3>
                    <p className="text-justify">
                      Arc Solutions Web offers a team of experts dedicated to
                      testing all of your Android apps for bugs thoroughly. We
                      ensure that each app is bug-free and performs at a high
                      level for your clients.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container mx-auto px-4 md:px-8">
        <div className="row">
          <div className="col-md-12">
            <div class="bg-white py-6 sm:py-8 lg:py-12">
              <div class="mx-auto max-w-screen-xl px-4 md:px-8">
                {/* <!-- text - start --> */}
                <div class="mb-10 md:mb-16">
                  <p class="mb-4 text-center font-bold text-blue-800 md:mb-6 sm:text-2xl">
                    Reasons our Clients Prefer Us as
                  </p>
                  <h1 class="text-center  font-bold">
                    Android App Development Company
                  </h1>

                  <p className="mt-3 text-xl text-dark">
                    We are a well-known custom android application development
                    company recognize for meeting modern business needs and
                    delivering best-in-class solutions at a reasonable price
                    worldwide. We rely on tried-and-true Android app development
                    software to create user-friendly, intuitive apps. Our highly
                    competent Android app developers have cracked the code to
                    build apps with over a million downloads here on Google Play
                    Store. We don't just make apps; we give you a tool to help
                    you grow your business. Our products have been successful in
                    the market because of the unwavering efforts put forth in
                    requirements research, UI/UX design, design and development,
                    testing, product launch, and support maintenance. To create
                    a work of art for you, we mix experience, innovation, and
                    technology. Here are some reasons we're the ideal choice for
                    the best Android app development services.
                  </p>
                </div>
                {/* <!-- text - end --> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Card section Start*/}
      <section class="py-10 bg-gray-100 sm:py-16 lg:py-24">
        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div class="max-w-2xl mx-auto text-center"></div>

          <div class="grid grid-cols-1 mx-auto mt-8 text-center lg:grid-cols-3 gap-x-8 gap-y-8 sm:mt-12 lg:mt-20 max-w-full">
            <div class="overflow-hidden rounded-lg shadow-lg hover-card">
              <div class="px-12 py-16">
                <div class="relative w-28 h-28 mx-auto img-container">
                  <img
                    class="relative object-cover w-28 h-28 mx-auto rounded-full img-zoom"
                    src={team}
                    alt=""
                  />
                  <div class="absolute top-0 right-0 flex items-center justify-center bg-blue-600 rounded-full w-8 h-8">
                    <svg
                      class="w-5 h-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M20.309 17.708C22.196 15.66 22.006 13.03 22 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292zm-11.007 0C11.19 15.66 10.999 13.03 10.993 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292z"></path>
                    </svg>
                  </div>
                </div>
                <blockquote class="mt-8">
                  <h2 class="text-2xl font-semibold text-gray-900 hover-h2">
                    Android App Expertise
                  </h2>
                  <p class="mt-4 text-lg text-gray-700 hover-p">
                    Arc solutions has invested immensely in Android App team
                    expansion and has a well-established team of Android App
                    experts.
                  </p>
                </blockquote>
              </div>
            </div>
            <div class="overflow-hidden rounded-lg shadow-lg hover-card">
              <div class="px-12 py-16">
                <div class="relative w-28 h-28 mx-auto img-container">
                  <img
                    class="relative object-cover w-28 h-28 mx-auto rounded-full img-zoom"
                    src={team5}
                    alt=""
                  />
                  <div class="absolute top-0 right-0 flex items-center justify-center bg-blue-600 rounded-full w-8 h-8">
                    <svg
                      class="w-5 h-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M20.309 17.708C22.196 15.66 22.006 13.03 22 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292zm-11.007 0C11.19 15.66 10.999 13.03 10.993 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292z"></path>
                    </svg>
                  </div>
                </div>
                <blockquote class="mt-8">
                  <h2 class="text-2xl font-semibold text-gray-900 hover-h2">
                    Cost Effective Partners
                  </h2>
                  <p class="mt-4 text-lg text-gray-700 hover-p">
                    Arc solutions provides cost-effective solutions, which makes
                    us the partner of your choice
                  </p>
                </blockquote>
              </div>
            </div>
            <div class="overflow-hidden rounded-lg shadow-lg hover-card">
              <div class="px-12 py-16">
                <div class="relative w-28 h-28 mx-auto img-container">
                  <img
                    class="relative object-cover w-28 h-28 mx-auto rounded-full img-zoom"
                    src={team6}
                    alt=""
                  />
                  <div class="absolute top-0 right-0 flex items-center justify-center bg-blue-600 rounded-full w-8 h-8">
                    <svg
                      class="w-5 h-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M20.309 17.708C22.196 15.66 22.006 13.03 22 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292zm-11.007 0C11.19 15.66 10.999 13.03 10.993 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292z"></path>
                    </svg>
                  </div>
                </div>
                <blockquote class="mt-8">
                  <h2 class="text-2xl font-semibold text-gray-900 hover-h2">
                    Agile Development & On-Time Delivery
                  </h2>
                  <p class="mt-4 text-lg text-gray-700 hover-p">
                    Our modern Agile approach to project management allows us to
                    deliver on time with quality.
                  </p>
                </blockquote>
              </div>
            </div>
            <div class="overflow-hidden rounded-lg shadow-lg hover-card">
              <div class="px-12 py-16">
                <div class="relative w-28 h-28 mx-auto img-container">
                  <img
                    class="relative object-cover w-28 h-28 mx-auto rounded-full img-zoom"
                    src={team2}
                    alt=""
                  />
                  <div class="absolute top-0 right-0 flex items-center justify-center bg-blue-600 rounded-full w-8 h-8">
                    <svg
                      class="w-5 h-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M20.309 17.708C22.196 15.66 22.006 13.03 22 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292zm-11.007 0C11.19 15.66 10.999 13.03 10.993 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292z"></path>
                    </svg>
                  </div>
                </div>
                <blockquote class="mt-8">
                  <h2 class="text-2xl font-semibold text-gray-900 hover-h2">
                    Easy and Regular Communication
                  </h2>
                  <p class="mt-4 text-lg text-gray-700 hover-p">
                    We use the communication channel that our client prefers,
                    making it easy for our clients to communicate.
                  </p>
                </blockquote>
              </div>
            </div>
            <div class="overflow-hidden rounded-lg shadow-lg hover-card">
              <div class="px-12 py-16">
                <div class="relative w-28 h-28 mx-auto img-container">
                  <img
                    class="relative object-cover w-28 h-28 mx-auto rounded-full img-zoom"
                    src={team3}
                    alt=""
                  />
                  <div class="absolute top-0 right-0 flex items-center justify-center bg-blue-600 rounded-full w-8 h-8">
                    <svg
                      class="w-5 h-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M20.309 17.708C22.196 15.66 22.006 13.03 22 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292zm-11.007 0C11.19 15.66 10.999 13.03 10.993 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292z"></path>
                    </svg>
                  </div>
                </div>
                <blockquote class="mt-8">
                  <h2 class="text-2xl font-semibold text-gray-900 hover-h2">
                    Free Post development Support
                  </h2>
                  <p class="mt-4 text-lg text-gray-700 hover-p">
                    Our clients can focus on marketing as we hold their back by
                    providing free and best post-development support.
                  </p>
                </blockquote>
              </div>
            </div>
            <div class="overflow-hidden rounded-lg shadow-lg hover-card">
              <div class="px-12 py-16">
                <div class="relative w-28 h-28 mx-auto img-container">
                  <img
                    class="relative object-cover w-28 h-28 mx-auto rounded-full img-zoom"
                    src={team4}
                    alt=""
                  />
                  <div class="absolute top-0 right-0 flex items-center justify-center bg-blue-600 rounded-full w-8 h-8">
                    <svg
                      class="w-5 h-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M20.309 17.708C22.196 15.66 22.006 13.03 22 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292zm-11.007 0C11.19 15.66 10.999 13.03 10.993 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292z"></path>
                    </svg>
                  </div>
                </div>
                <blockquote class="mt-8">
                  <h2 class="text-2xl font-semibold text-gray-900 hover-h2">
                    Starts at $18/H
                  </h2>
                  <p class="mt-4 text-lg text-gray-700 hover-p">
                    Our hourly rates for dedicated resources start at $18 /
                    Hour, which are the most competitive in the market.
                  </p>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Card section End*/}

      {/* Industries Section Start */}
      <section
        className="py-10 from-fuchsia-600 to-blue-600 sm:py-16 lg:py-24"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="mx-auto text-left sm:text-center">
            <h2 className="text-3xl font-bold leading-tight text-dark sm:text-4xl lg:text-5xl lg:leading-tight">
              Industries We Have Worked With
            </h2>
            <p className="mt-4 text-xl text-dark">
              We handcraft full-stack app development solutions to clients
              worldwide. We deliver only the best mobile apps from various
              sectors like eCommerce, IT, Sports, Healthcare, Fitness,
              Education, etc. We have a creative design team to make your mobile
              app look more appealing to your end-users. We use advanced
              technologies and follow modern trends to build a great mobile app
              that meets your expectations. Arc Solutons web is recognized as a
              top-rated app development company and a top{" "}
              <span className="font-bold text-blue-800">
                custom software development company
              </span>{" "}
              on reputed platforms like Clutch, GoodFirms, DesignRush, and
              Business of Apps.
            </p>
          </div>
        </div>
        <div className=" py-6 sm:py-8 lg:py-12">
          <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 sm:gap-6 xl:gap-8">
              <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-white shadow-lg md:h-50">
                <div className="absolute inset-0 flex items-center justify-center bg-blue-600">
                  <FontAwesomeIcon
                    icon={faGraduationCap}
                    size="2x"
                    className=" text-white"
                  />
                </div>
                <div className="pointer-events-none absolute inset-0 bg-gradient-to-t  via-transparent to-transparent opacity-50"></div>
                <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">
                  Education
                </span>
              </div>
              <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-white shadow-lg md:h-50">
                <div className="absolute inset-0 flex items-center justify-center bg-blue-600">
                  <FontAwesomeIcon
                    icon={faHeartbeat}
                    size="2x"
                    className=" text-white"
                  />
                </div>
                <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">
                  Healthcare
                </span>
              </div>
              <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-white shadow-lg md:h-50">
                <div className="absolute inset-0 flex items-center justify-center bg-blue-600">
                  <FontAwesomeIcon
                    icon={faDumbbell}
                    size="2x"
                    className=" text-white"
                  />
                </div>
                <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">
                  Fitness
                </span>
              </div>
              <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-white shadow-lg md:h-50">
                <div className="absolute inset-0 flex items-center justify-center bg-blue-600">
                  <FontAwesomeIcon
                    icon={faShoppingCart}
                    size="2x"
                    className="text-gray-800 text-white"
                  />
                </div>
                <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">
                  Shopping
                </span>
              </div>
              <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-white shadow-lg md:h-50">
                <div className="absolute inset-0 flex items-center justify-center bg-orange-600">
                  <FontAwesomeIcon
                    icon={faLaptopCode}
                    size="2x"
                    className="text-gray-800 text-white"
                  />
                </div>
                <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">
                  IT
                </span>
              </div>
              <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-white shadow-lg md:h-50">
                <div className="absolute inset-0 flex items-center justify-center bg-orange-600">
                  <FontAwesomeIcon
                    icon={faShoppingBag}
                    size="2x"
                    className="text-gray-800 text-white"
                  />
                </div>
                <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">
                  eCommerce
                </span>
              </div>
              <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-white shadow-lg md:h-50">
                <div className="absolute inset-0 flex items-center justify-center bg-orange-600">
                  <FontAwesomeIcon
                    icon={faBasketballBall}
                    size="2x"
                    className="text-gray-800 text-white"
                  />
                </div>
                <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                <span className="relative ml-4 mb-3 inline-block text-sm text-white text-xenter md:ml-5 md:text-lg">
                  Sports
                </span>
              </div>
              <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-white shadow-lg md:h-50">
                <div className="absolute inset-0 flex items-center justify-center bg-orange-600">
                  <FontAwesomeIcon
                    icon={faBriefcase}
                    size="2x"
                    className="text-white text-xenter text-white"
                  />
                </div>
                <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">
                  Jobs
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Industries Section End */}

      {/* Connect with all apps Start */}
      <section class="pt-10 pb-8 overflow-hidden bg-gray-100 sm:pt-16 lg:pt-24">
        <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div class="max-w-2xl mx-auto text-center">
            <h2 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">
              Connect with all apps
            </h2>
            {/* <p class="max-w-xl mx-auto mt-4 text-base leading-relaxed text-gray-600">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint. Velit officia consequat duis.
            </p> */}
          </div>
        </div>

        <img
          class="w-full min-w-full mx-auto mt-12 scale-150 max-w-7xl lg:min-w-0 lg:mt-0 lg:scale-100"
          src={multi}
          alt=""
        />
      </section>

      {/* Connect with all apps End */}

      <Footer />
    </div>
  );
}

export default AndroidDeveloper;
