import React, { useState } from "react";
import logo from "../img/logoarc.png";
import { Link } from 'react-router-dom';
import "./nav.css";

function App() {
  const [isNavVisible, setIsNavVisible] = useState(false);

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  const closeNav = () => {
    setIsNavVisible(false);
  };

  return (
    <div className="App">
      <section className="bg-blue-900 p-0 m-0 se">
        <div className="container p-1">
          <div className="hidden lg:flex lg:flex-row">
            {/* Support Section */}
            <div className="lg:w-1/2 w-full lg:mb-0 lg:pr-2 flex items-center mb-2 lg:mb-0">
              <span className="font-semibold text-white">Support:</span>
              <span className="text-white lg:ml-2 lg:inline hidden">
                0135-4083251
              </span>
              <span className="text-white lg:hidden block">0135-4083251</span>
            </div>
            <div className="flex items-center">
              <marquee className="text-white fw-bold">
                Building the Future with Custom Website and Software Services
              </marquee>
            </div>
            {/* Sales Section */}
            <div className="lg:w-1/2 w-full lg:pl-2 flex items-center justify-end mb-2 lg:mb-0">
              <span className="font-semibold text-white">Sales:</span>
              <span className="text-white lg:ml-2 lg:inline hidden">
                +91-9219666443
              </span>
              <span className="text-white lg:hidden block">+91-9219666443</span>
            </div>
          </div>

          {/* Mobile View: Side by Side */}
          <div className="flex flex-col lg:hidden mt-2">
            <div className="flex justify-between">
              {/* Support Section (Mobile) */}
              <div className="flex items-center">
                <span className="font-semibold text-white">Support:</span>
                <span className="text-white ml-2">0135-4083251</span>
              </div>
              {/* Sales Section (Mobile) */}
              <div className="flex items-center">
                <span className="font-semibold text-white">Sales:</span>
                <span className="text-white ml-2">+91-9219666443</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row"></div>
      </div>
      <nav className="navbar">
        <div className="logo">
          <img src={logo} alt="Logo" className="w-100" />
        </div>
        <div className="navbar-toggle me-3" onClick={toggleNav}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className={`nav-links ${isNavVisible ? "show" : ""}`}>
          <Link to="/" className="fs-6 fw-bold" onClick={closeNav}>
            Home
          </Link>
          <div className="dropdown">
            <Link className="dropbtn fs-6 fw-bold" to="#digital-market" onClick={closeNav}>
              Services
              <i className="fa fa-caret-down"></i>
            </Link>
            <div
              className="dropdown-content"
              style={{ width: "714px", zIndex: "999" }}
            >
              <div className="row">
                <div className="col-lg-3 border-r-2 border-blue-900">
                  <h5 className="heading_res fw-bold bg-orange-500 text-white p-2" id="SOCIAL">
                    Portal Development
                  </h5>
                  <Link to="/Job_Portal" className="nav_bar_responsive" onClick={closeNav}>
                    Job Portal
                  </Link>
                  <Link to="/AstroPortal" className="nav_bar_responsive" onClick={closeNav}>
                    Astro Portal
                  </Link>
                  <Link to="/E-commerce" className="nav_bar_responsive" onClick={closeNav}>
                    e-Commerce Portal
                  </Link>
                  <Link to="/News" className="nav_bar_responsive" onClick={closeNav}>
                    News portal
                  </Link>
                  <Link to="/Sport" className="nav_bar_responsive" onClick={closeNav}>
                    Sports Portal
                  </Link>
                </div>
                <div className="col-lg-3 border-r-2 border-blue-900">
                  <h5 className="heading_res fw-bold bg-orange-500 text-white p-2" id="SOCIAL">
                    App Development
                  </h5>
                  <Link to="/Andriod-Developer" className="nav_bar_responsive" onClick={closeNav}>
                    Android
                  </Link>
                  <Link to="/Ios_developer" className="nav_bar_responsive" onClick={closeNav}>
                    IOS
                  </Link>
                </div>
                <div className="col-lg-3 border-r-2 border-blue-900">
                  <h5 className="heading_res fw-bold bg-orange-500 text-white p-2" id="SOCIAL">
                    E-vidyalaya
                  </h5>
                  <Link to="https://india.arcsolutions.org/" target="_blank" className="nav_bar_responsive" onClick={closeNav}>
                    Admin Login
                  </Link>
                  <Link to="https://india.arcsolutions.org/" target="_blank" className="nav_bar_responsive" onClick={closeNav}>
                    User Login
                  </Link>
                  <Link to="https://india.arcsolutions.org/" target="_blank" className="nav_bar_responsive" onClick={closeNav}>
                    Student Fee
                  </Link>
                  <Link to="https://india.arcsolutions.org/" target="_blank" className="nav_bar_responsive" onClick={closeNav}>
                    Employee Salary
                  </Link>
                </div>
                <div className="col-lg-3">
                  <h5 className="heading_res fw-bold bg-orange-500 text-white p-2" id="SOCIAL">
                    E-attendance
                  </h5>
                  <Link to="https://test.arcsolutions.org/login.php" target="_blank" className="nav_bar_responsive" onClick={closeNav}>
                    Admin Login
                  </Link>
                  <Link to="https://test.arcsolutions.org/login.php" target="_blank" className="nav_bar_responsive" onClick={closeNav}>
                    User Login
                  </Link>
                  <Link to="https://test.arcsolutions.org/login.php" target="_blank" className="nav_bar_responsive" onClick={closeNav}>
                    Employee Registration
                  </Link>
                  <Link to="https://test.arcsolutions.org/login.php" target="_blank" className="nav_bar_responsive" onClick={closeNav}>
                    Employee Attendance
                  </Link>
                  <Link to="https://test.arcsolutions.org/login.php" target="_blank" className="nav_bar_responsive" onClick={closeNav}>
                    Employee Report
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="dropdown">
            <Link className="dropbtn ms-0 fs-6 fw-bold" to="#digital-market" onClick={closeNav}>
              Digital Market
              <i className="fa fa-caret-down"></i>
            </Link>
            <div
              className="dropdown-content"
              style={{ width: "500px", zIndex: "999" }}
            >
              <div className="row">
                <div className="column border-r-2 border-blue-900">
                  <h5 className="heading_res fw-bold bg-orange-500 text-white p-2" id="SOCIAL">
                    Social Media
                  </h5>
                  <Link to="/Facebook" className="nav_bar_responsive" onClick={closeNav}>
                    Facebook
                  </Link>
                  <Link to="/Instagram" className="nav_bar_responsive" onClick={closeNav}>
                    Instagram
                  </Link>
                  <Link to="/Twitter" className="nav_bar_responsive" onClick={closeNav}>
                    Twitter
                  </Link>
                  <Link to="/LinkedIn" className="nav_bar_responsive" onClick={closeNav}>
                    LinkedIn
                  </Link>
                </div>
                <div className="column border-r-2 border-blue-900">
                  <h5 className="heading_res fw-bold bg-orange-500 text-white p-2" id="SOCIAL">
                    SEO
                  </h5>
                  <Link to="/Google-Promotions" className="nav_bar_responsive" onClick={closeNav}>
                    Google promotion
                  </Link>
                  <Link to="/PPC" className="nav_bar_responsive" onClick={closeNav}>
                    PPC
                  </Link>
                  <Link to="/Website-traffic" className="nav_bar_responsive" onClick={closeNav}>
                    Website Traffic
                  </Link>
                </div>
                <div className="column">
                  <h5 className="heading_res fw-bold bg-orange-500 text-white p-2" id="SOCIAL">
                    Other
                  </h5>
                  <Link to="/Bulksms" className="nav_bar_responsive" onClick={closeNav}>
                    Bulk SMS
                  </Link>
                  <Link to="/Bulkwhatsapp" className="nav_bar_responsive" onClick={closeNav}>
                    Bulk WhatsApp
                  </Link>
                  <Link to="/Facebook-likes" className="nav_bar_responsive" onClick={closeNav}>
                    Facebook Likes
                  </Link>
                  <Link to="/Instagramlikes" className="nav_bar_responsive" onClick={closeNav}>
                    Instagram Likes
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <Link to="/Technology" className="fs-6 fw-bold" onClick={closeNav}>
          Technology
          </Link>
          
          <Link to="/Portfolio" className="fs-6 fw-bold" onClick={closeNav}>
            Our Portfolio
          </Link>

          <div className="dropdown" style={{ zIndex: "999" }}>
            <Link className="dropbtn ms-0 fs-6 fw-bold" to="#digital-market" onClick={closeNav}>
              Contact
              <i className="fa fa-caret-down"></i>
            </Link>
            <div className="dropdown-content">
              <div className="row">
                <div className="column">
                  <Link to="/Contact" className="nav_bar_responsive" onClick={closeNav}>
                    Domestic
                  </Link>
                  <Link to="/International" className="nav_bar_responsive" onClick={closeNav}>
                    International
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="login-btn ms-5">
            <a
              href="https://www.arcsolutions.in/"
              className="fs-6 me-4 fw-bold client_login"
              onClick={closeNav}
            >
              Client Login
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default App;
