import React from "react";
import { Footer } from "./Footer";

function Product_developer() {
  return (
    <div className="bg-dark">
      <div class="py-6 sm:py-8 lg:py-12">
        <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
          <div class="rounded-lg px-4 py-6 md:py-8 lg:py-12">
            <h1
              className="mb-4 text-center text-2xl font-bold text-gray-800 text-white"
              style={{ fontSize: "50px" }}
            >
              Product Development
            </h1>
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <p className="text-white font-bold text-xl text-center mt-5">While developing a new digital product we focus on speed to market and rely on the voice of the customer to determine product features. As we partner closely with the client team you can expect us to be an independent voice in a room offering data to support our arguments. Below is a typical process we follow in product development:</p>
              </div>
              <div className="col-md-2"></div>
            </div>
            <div class="row align-items-center">
              <div className="col-md-3">
                <span class="rounded-circle text-black fw-bold border border-solid bg-white p-2 fs-5">1</span>
                
              </div>
              <div className="col-md-9">
                  <p ClassName="h3">Ideation and research</p>
                  <p className="text-white">We first develop a deep understanding of the product’s goals and objectives, conduct market research, and screen initial product ideas to identify the most viable options.</p>
                  
              </div>
               
            </div>
        </div>
      </div>
    </div>

      <Footer />
    </div>
  );
}

export default Product_developer;
