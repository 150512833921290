import { React } from "react";
import astroAudio from "../img/astro1.mp4"; // Importing the audio
import { Helmet } from "react-helmet-async"; // Import Helmet
import Job_Portal2 from "../img/astro1.png";
import Astro from "../img/astro2.png";
import service from "../img/services-icons.png";
import { Footer } from "./Footer";

function AstroPortal() {
  return (
    <div>
      <Helmet>
        <title>Arc Solutions || Astro Portal</title>
        <meta name="description" content="This is a description of the page content." />
        <meta name="keywords" content="React, carousel, example, meta tags" />
        <meta name="author" content="Your Name or Company" />
        <meta property="og:title" content="Your Page Title" />
        <meta property="og:description" content="This is a description of the page content." />
        <meta property="og:image" content="URL_to_your_image" />
        <meta property="og:url" content="Your_page_URL" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Your Page Title" />
        <meta name="twitter:description" content="This is a description of the page content." />
        <meta name="twitter:image" content="URL_to_your_image" />
      </Helmet>
      {/* Banner Video Job Portal Start */}
      <video className="d-block w-100" autoPlay loop muted>
        <source src={astroAudio} type="audio/mp3" style={{ height: "80px" }} />
        Your browser does not support the audio element.
      </video>
      {/* Banner Job Portal End */}

      <div class="bg-white py-6 sm:py-8 lg:py-12">
      <div class="flex justify-center">
  <div class="mx-auto max-w-screen-xl px-4 md:px-8">
    <div class="grid gap-8 md:grid-cols-2 lg:gap-12">
      <div class="flex justify-center">
        <div class="h-64 overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-auto">
          <img
            src={Job_Portal2}
            style={{ height: "300px", objectFit: "cover" }}
            loading="lazy"
            alt="Photo by Martin Sanchez"
            class="h-full w-full object-cover object-center"
          />
        </div>
      </div>

      <div class="md:pt-8 text-center md:text-left">
        <p class="font-bold text-black">
          Our Astro Portal
        </p>

        <h1 class="mb-4 text-2xl font-bold text-black sm:text-3xl md:mb-6">
          Welcome to Astro Portal
        </h1>

        <p class="mb-6 text-black sm:text-lg md:mb-8">
          Arc Solution is a pioneering astrology portal company that
          utilizes cutting-edge technology and AI to offer personalized
          astrological insights. Their platform leverages a sophisticated
          blend of Python for backend processing and React for intuitive
          frontend interfaces. With a focus on user experience and
          accuracy, Arc Solution delivers comprehensive horoscopes,
          compatibility analyses, and predictive tools, making astrology
          accessible and insightful for a global audience.
          <br />
          <br />
        </p>
      </div>
    </div>
  </div>
</div>

      </div>

      {/* Online Astrology  Portal Start */}
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-7">
            <br />
            <br /> <br />
            <br />
            <h3>Online Astrology Portal</h3>
            <p style={{ fontSize: "20px" }}>
              Arc Solution offers personalized astrology predictions through its
              advanced portal. Using sophisticated algorithms and user data, it
              provides accurate horoscopes, compatibility assessments, and
              insightful forecasts. Whether users seek daily guidance or
              long-term planning, Arc Solution's astrology portal delivers
              tailored predictions, empowering individuals to navigate life's
              challenges with clarity and confidence.
            </p>
          </div>
          <div className="col-md-5">
            <img src={Astro} className="" />
          </div>
        </div>
      </div>
      {/* Online Job Portal End */}

      <br />

      {/* Connect With App Start */}
      <section class="pt-10 pb-8 overflow-hidden bg-gray-100 sm:pt-16 lg:pt-24">
        <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div class="max-w-2xl mx-auto text-center">
            <h2 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">
              Connect with all apps
            </h2>
          </div>
        </div>

        <center><img
              src={service}
              style={{ objectFit: "cover" }}
              loading="lazy"
              alt="Photo by Martin Sanchez"
              class="w-50 mt-5 object-cover object-center"
            /></center>
      </section>
      {/* Connect With App End */}

      <Footer />
    </div>
  );
}

export default AstroPortal;
