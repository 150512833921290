import React from "react";
import { Helmet } from "react-helmet-async"; // Import Helmet
import { Footer } from "../component/Footer";
import banner6 from "../img/About2.jpg";
import mission from "../img/mission1.jpg";
import success from "../img/success.jpg";

function About() {
  return (
    <div>
      <Helmet>
        <title>Arc Solutions || About</title>
        <meta name="description" content="Learn more about Arc Solutions, a leading IT Solution provider." />
        <meta name="keywords" content="Arc Solutions, IT services, Web Development, ERP Solutions, Software Development" />
        <meta name="author" content="Arc Solutions" />
      </Helmet>

      <img
        className="d-block w-100"
        src={banner6}
        alt="Banner"
        style={{ height: "150px", objectFit: "cover" }}
      />
      <center>
        <h1 className="about mt-5">About Arc Solutions</h1>
      </center>

      <div className="container mt-4">
        <div className="row">
          <p style={{ textAlign: "center" }}>
            Arc Solutions is a leading IT Solution provider company based in
            India With Strong Focus towards, top-of-the-line Web Application
            Development, E-Commerce portal development, ERP Solutions, Software
            Development, Website Designing, Dynamic Website Designing, Mobile
            App Development, SEO/SMO Services, Online Marketing, Web Portal
            Development, in simple words, we are a one-stop solution center for all
            your IT needs using the latest technologies available in the
            market...
          </p>
        </div>

        <div className="contianer">
          <div className="row mt-5">
            <div className="col-md-5 ms-3">
              <img className="" src={mission} alt="about" />
            </div>
            <div className="col-md-6 ms-3">
              <h2 className="fw-bold;">Our Mission:</h2>
              <p className=" fs-5">
                Our mission at Arc Solutions is to be innovative & fluent to the
                client need & provide them with advanced quality products & solutions
                for all their IT needs by achieving excellence in project execution...
              </p>
            </div>
          </div>
        </div>

        <div className="contianer">
          <div className="row mt-5">
            <div className="col-md-6 ms-3">
              <h2 className="fw-bold;">Our Success:</h2>
              <p className=" fs-5">
                Our mission at Arc Solutions is to be innovative & fluent to the
                client need & provide them with advanced quality products & solutions...
              </p>
            </div>
            <div className="col-md-5 ms-3">
              <img className="" src={success} alt="about" />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default About;
