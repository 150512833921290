import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Footer } from "./Footer";
import { Helmet } from "react-helmet-async"; // Import Helmet
// Additional images for cards3
import digitalMarketing from "../img/digitalMarketing.png";
import LinkedIn1 from "../img/bulksms.png";
import LinkedIn2 from "../img/sms.png";


const BulkWhatsapp = () => {
    return (
        <>
        <Helmet>
        <title>Arc Solutions || Bulk Whatsapp</title>
        <meta name="description" content="Learn more about Arc Solutions, a leading IT Solution provider." />
        <meta name="keywords" content="Arc Solutions, IT services, Web Development, ERP Solutions, Software Development" />
        <meta name="author" content="Arc Solutions" />
      </Helmet>
            <div className="bg-orange-500 h-60 flex items-center justify-center">
                <div className="flex flex-col max-w-4xl items-center justify-center">
                    <div className="text-white text-4xl font-bold">BulkWhatsapp</div><br></br>
                    <a href="/" class="text-white text-decoration-none  hover:decoration-4">Home/Bulkwhatsapp</a>
                    <div className="text-white">

                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-7">
                        <h3>Leading Bulk Whatsapp Service Providers</h3>
                        <p>
                        At Our Company, we recognize the power of instant messaging in today's business landscape. Our bulk WhatsApp services provide a seamless, cost-effective solution to engage your audience, notify your staff, and promote your products or services. With WhatsApp's widespread popularity and user-friendly interface, you can reach customers directly on their preferred platform, ensuring higher open rates and more meaningful interactions. Harness the immediacy and personal touch of WhatsApp to create stronger connections and drive your business forward.</p>
                       
                    </div>
                    <div className="col-md-5">
                        <img
                            src={LinkedIn2}
                            className="w-50"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <h5>Why Choose Us</h5>
                        Reliable API Integration
Integrate our powerful WhatsApp API into your systems effortlessly. Automate and streamline your messaging processes with ease, enhancing operational efficiency.

Intuitive Web Platform
Our user-friendly web platform is designed for everyone, from tech-savvy marketers to small business owners. Manage your campaigns, schedule messages, and analyze results with just a few clicks.

Personalized Messaging
Stand out by delivering personalized messages. Tailor your content with recipient names and other dynamic fields to make a lasting impact.

Advanced Scheduling
Schedule your messages to be sent at the perfect time for your audience, ensuring maximum engagement and effectiveness.

Detailed Delivery Reports
Monitor your campaigns with comprehensive delivery reports. Track success rates, identify trends, and optimize your strategies based on real-time data.

Interactive Two-Way Messaging
Enable two-way communication with your audience. Receive responses directly and engage in meaningful conversations to build stronger relationships.

Robust Contact Management
Organize and segment your contact lists effortlessly. Import, export, and manage your contacts to ensure your messages reach the right people.

Ready-to-Use Templates
Get started quickly with our pre-designed message templates. Perfect for common use cases, these templates save you time and effort.

Multi-Language Support
Communicate with a global audience. Our platform supports multiple languages, allowing you to connect with diverse markets.

High Throughput
Send thousands of messages per second with our high-capacity infrastructure. Ensure timely delivery even during peak periods.
                    </div>
                </div>
                
            </div>
            <Footer />
        </>
    );
};

export default BulkWhatsapp;
