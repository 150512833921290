import { React } from "react";
import news from "../img/News1.jpg"; // Importing the audio
import News2 from "../img/news2.png";
import { Helmet } from "react-helmet-async"; // Import Helmet
import { Footer } from "../component/Footer";

function News() {
  return (
    <div>
      <Helmet>
        <title>Arc Solutions || News Portal</title>
        <meta name="description" content="This is a description of the page content." />
        <meta name="keywords" content="React, carousel, example, meta tags" />
        <meta name="author" content="Your Name or Company" />
        <meta property="og:title" content="Your Page Title" />
        <meta property="og:description" content="This is a description of the page content." />
        <meta property="og:image" content="URL_to_your_image" />
        <meta property="og:url" content="Your_page_URL" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Your Page Title" />
        <meta name="twitter:description" content="This is a description of the page content." />
        <meta name="twitter:image" content="URL_to_your_image" />
      </Helmet>
      {/* Banner Video Job Portal Start */}
      <img
        class="mt-5 w-full min-w-full mx-auto mt-12 scale-150 max-w-7xl lg:min-w-0 lg:mt-0 lg:scale-100"
        src={news}
        alt=""
      />
      {/* Banner Job Portal End */}
      {/* <center><h1>News Portal Development</h1></center> */}

      {/* Online News  Portal Start */}
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-7">
            <br />
            <br /> <br />
            <br />
            <h3 className="font-bold">Online News Portal</h3>
            <p style={{ fontSize: "20px" }}>
              News Portal is an online communication medium for internet users
              which are read all over the world. Arc Solutions a leading Web
              Development company, provide an appealing and efficient news
              portal development services.
              <br />
              The news portal development allows to publish publications, press
              releases, columns, articles, blogs and other news related content.
              We create and develop innovative website, bespoke and modern style
              portals with content management system (CMS). We provide
              user-friendly website designs in order to navigate easily for
              potential readers along with SEO concepts for higher visibility in
              page rankings.
              <br />
              <br />
              We provide sections of local and international news, lifestyle,
              entertainments, jobs, educations, business, science & technology,
              and other features exclusively for the news portal development.
            </p>
          </div>
          <div className="col-md-5">
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <img src={News2} className="" />
          </div>
          <h3 className="font-bold">Our features include:</h3>
          <div class="flex items-center gap-2 text-black">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 shrink-0"
              fill="none"
              viewBox="0 0 16 16"
            >
              <circle
                cx="8"
                cy="8"
                r="8"
                fill="currentColor"
                class="text-gray-300"
              />
              <circle
                cx="8"
                cy="8"
                r="3"
                fill="currentColor"
                class="text-gray-500"
              />
            </svg>

            <span class="text-gray-600 text-black">
              Customize news portal development
            </span>
          </div>
          <div class="flex items-center gap-2 text-black">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 shrink-0"
              fill="none"
              viewBox="0 0 16 16"
            >
              <circle
                cx="8"
                cy="8"
                r="8"
                fill="currentColor"
                class="text-gray-300"
              />
              <circle
                cx="8"
                cy="8"
                r="3"
                fill="currentColor"
                class="text-gray-500"
              />
            </svg>

            <span class="text-gray-600 text-black">
              Customize News Portal design
            </span>
          </div>
          <div class="flex items-center gap-2 text-black">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 shrink-0"
              fill="none"
              viewBox="0 0 16 16"
            >
              <circle
                cx="8"
                cy="8"
                r="8"
                fill="currentColor"
                class="text-gray-300"
              />
              <circle
                cx="8"
                cy="8"
                r="3"
                fill="currentColor"
                class="text-gray-500"
              />
            </svg>

            <span class="text-gray-600 text-black">Very good interface</span>
          </div>
          <div class="flex items-center gap-2 text-black">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 shrink-0"
              fill="none"
              viewBox="0 0 16 16"
            >
              <circle
                cx="8"
                cy="8"
                r="8"
                fill="currentColor"
                class="text-gray-300"
              />
              <circle
                cx="8"
                cy="8"
                r="3"
                fill="currentColor"
                class="text-gray-500"
              />
            </svg>

            <span class="text-gray-600 text-black">
              Maintenance and Support
            </span>
          </div>
          <div class="flex items-center gap-2 text-black">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 shrink-0"
              fill="none"
              viewBox="0 0 16 16"
            >
              <circle
                cx="8"
                cy="8"
                r="8"
                fill="currentColor"
                class="text-gray-300"
              />
              <circle
                cx="8"
                cy="8"
                r="3"
                fill="currentColor"
                class="text-gray-500"
              />
            </svg>

            <span class="text-gray-600 text-black">
              Images and and Video posts
            </span>
          </div>
          <div class="flex items-center gap-2 text-black">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 shrink-0"
              fill="none"
              viewBox="0 0 16 16"
            >
              <circle
                cx="8"
                cy="8"
                r="8"
                fill="currentColor"
                class="text-gray-300"
              />
              <circle
                cx="8"
                cy="8"
                r="3"
                fill="currentColor"
                class="text-gray-500"
              />
            </svg>

            <span class="text-gray-600 text-black">
              Add/delete/edit features
            </span>
          </div>
          <div class="flex items-center gap-2 text-black">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 shrink-0"
              fill="none"
              viewBox="0 0 16 16"
            >
              <circle
                cx="8"
                cy="8"
                r="8"
                fill="currentColor"
                class="text-gray-300"
              />
              <circle
                cx="8"
                cy="8"
                r="3"
                fill="currentColor"
                class="text-gray-500"
              />
            </svg>

            <span class="text-gray-600 text-black">
              Multilevel features (Admin, Author, Users and more)
            </span>
          </div>
          <div class="flex items-center gap-2 text-black">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 shrink-0"
              fill="none"
              viewBox="0 0 16 16"
            >
              <circle
                cx="8"
                cy="8"
                r="8"
                fill="currentColor"
                class="text-gray-300"
              />
              <circle
                cx="8"
                cy="8"
                r="3"
                fill="currentColor"
                class="text-gray-500"
              />
            </svg>

            <span class="text-gray-600 text-black">
              Add/edit/delete advertisement or banner
            </span>
          </div>
          <div class="flex items-center gap-2 text-black">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 shrink-0"
              fill="none"
              viewBox="0 0 16 16"
            >
              <circle
                cx="8"
                cy="8"
                r="8"
                fill="currentColor"
                class="text-gray-300"
              />
              <circle
                cx="8"
                cy="8"
                r="3"
                fill="currentColor"
                class="text-gray-500"
              />
            </svg>

            <span class="text-gray-600 text-black">News highlight</span>
          </div>
          <div class="flex items-center gap-2 text-black">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 shrink-0"
              fill="none"
              viewBox="0 0 16 16"
            >
              <circle
                cx="8"
                cy="8"
                r="8"
                fill="currentColor"
                class="text-gray-300"
              />
              <circle
                cx="8"
                cy="8"
                r="3"
                fill="currentColor"
                class="text-gray-500"
              />
            </svg>

            <span class="text-gray-600 text-black">
              Easy managing administration panel
            </span>
          </div>
          <div class="flex items-center gap-2 text-black">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 shrink-0"
              fill="none"
              viewBox="0 0 16 16"
            >
              <circle
                cx="8"
                cy="8"
                r="8"
                fill="currentColor"
                class="text-gray-300"
              />
              <circle
                cx="8"
                cy="8"
                r="3"
                fill="currentColor"
                class="text-gray-500"
              />
            </svg>

            <span class="text-gray-600 text-black">Latest news update</span>
          </div>
        </div>
      </div>
      {/* Online Job Portal End */}

      <br />
      {/* Connect With App Start */}
      <section class="pt-10 pb-8 overflow-hidden bg-gray-100 sm:pt-16 lg:pt-24">
        <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div class="max-w-2xl mx-auto text-center">
            <h2 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">
              Connect with all apps
            </h2>
          </div>
        </div>

        <img
          class="w-full min-w-full mx-auto mt-12 scale-150 max-w-7xl lg:min-w-0 lg:mt-0 lg:scale-100"
          src="https://cdn.rareblocks.xyz/collection/celebration/images/integration/1/services-icons.png"
          alt=""
        />
      </section>
      {/* Connect With App End */}

      <Footer />
    </div>
  );
}

export default News;
