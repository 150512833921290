import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Nav from "./component/Nav";
import Home from "./component/Home";
import About from "./component/About";
import Contact from "./component/Contact";
import Course from "./component/Course";
import Facebook from "./component/Facebook";
import Instagram from "./component/Instagram";
import News from "./component/News";
import E_commerce from "./component/E-commerce";
import AstroPortal from "./component/AstroPortal";
import Sport from "./component/Sport";
import PPC from "./component/PPC";
import Bulksms from "./component/Bulksms";
import Facebook_likes from "./component/Facebook-likes";
import Data from "./component/Data";
import Opr from "./component/Opr";
import Opr1 from "./component/Opr1";
import Student from "./component/Student";
import LinkedIn from "./component/LinkedIn";
import Twitter from "./component/Twitter";
import DevelopmentServices from "./component/DevelopmentServices.js";
import International from "./component/International";
import Bulkwhatsapp from "./component/Bulk-whatsapp";
import Instagramlikes from "./component/Instagramlikes";
import Calculater from "./component/Calculater";
import API from "./component/API";
import API2 from "./component/API2";
import Show from "./component/Show";
import Insert from "./component/Insert";
import Delete from "./component/Delete";
import Edit from "./component/Edit";
import Login from "./component/Login";
import Technology from "./component/Technology";
import NotFound from "./component/NotFound";
import Portfolio from "./component/Portfolio";
import Andriod_Developer from "./component/Andriod_Developer";
import Ios_developer from "./component/Ios_developer";
import Job_Portal from "./component/Job_Portal";
import Product_Development from "./component/Product-Development";
import Google_Promotions from "./component/Google_Promotions";
import Website_traffic from "./component/Website_traffic";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Nav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/Portfolio" element={<Portfolio />} />
          <Route path="/Andriod-Developer" element={<Andriod_Developer />} />
          <Route path="/Technology" element={<Technology />} />
          <Route path="/Ios_developer" element={<Ios_developer />} />
          <Route path="/Job_portal" element={<Job_Portal />} />
          <Route path="/Course" element={<Course />} />
          <Route path="/Facebook" element={<Facebook />} />
          <Route path="/Instagram" element={<Instagram />} />
          <Route path="/AstroPortal" element={<AstroPortal />} />
          <Route path="/News" element={<News />} />
          <Route path="/E-commerce" element={<E_commerce />} />
          <Route path="/Google-Promotions" element={<Google_Promotions />} />
          <Route path="/Website-traffic" element={<Website_traffic />} />
          <Route path="/DevelopmentServices" element={<DevelopmentServices />} />
          <Route
            path="/Product-Development"
            element={<Product_Development />}
          />
          <Route path="/Sport" element={<Sport />} />
          <Route path="/International" element={<International />} />
          <Route path="/PPC" element={<PPC />} />
          <Route path="/Bulksms" element={<Bulksms />} />
          <Route path="/Bulkwhatsapp" element={<Bulkwhatsapp />} />
          <Route path="/Facebook-likes" element={<Facebook_likes />} />
          <Route path="/Instagramlikes" element={<Instagramlikes />} />
          <Route path="/LinkedIn" element={<LinkedIn />} />
          <Route path="/Twitter" element={<Twitter />} />
          <Route path="/data" element={<Data />} />
          <Route path="/student" element={<Student />} />
          <Route path="/opr" element={<Opr />} />
          <Route path="/opr1" element={<Opr1 />} />
          <Route path="/Calculater" element={<Calculater />} />
          <Route path="/API" element={<API />} />
          <Route path="/API2" element={<API2 />} />
          <Route path="/fpi1" element={<Show />} />
          <Route path="/Insert" element={<Insert />} />
          <Route path="/delete/:id" element={<Delete />} />
          <Route path="/Edit" element={<Edit />} />
          <Route path="/Login" element={<Login />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;
