import React from "react";
import { Footer } from "./Footer";
// import Technology1 from "../img/technology1.png";
import Technology2 from "../img/technology1.png";
import { Helmet } from "react-helmet-async"; // Import Helmet
// images for services
import service from "../img/python.png";
import service1 from "../img/web.png";
import service2 from "../img/php.png";
import service3 from "../img/1software.png";
import service4 from "../img/java.png";
import service5 from "../img/API.png";
import service6 from "../img/python1.png";
import service7 from "../img/java1.png";
import service8 from "../img/web1.png";
import service9 from "../img/api1.png";
import service10 from "../img/redux.png";
import service11 from "../img/html.png";
import service12 from "../img/laravel.png";
import service13 from "../img/database.png";
import service14 from "../img/fullstack.jpg";

function Technology() {
  return (
    <div>
       <Helmet>
        <title>Arc Solutions || Technology</title>
        <meta name="description" content="Learn more about Arc Solutions, a leading IT Solution provider." />
        <meta name="keywords" content="Arc Solutions, IT services, Web Development, ERP Solutions, Software Development" />
        <meta name="author" content="Arc Solutions" />
      </Helmet>
      <section class="bg-[#90f5ab] bg-opacity-30 py-10 sm:py-16 lg:py-24">
        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div class="grid items-center grid-cols-1 gap-12 lg:grid-cols-2">
            <div>
              <p class="text-base font-semibold tracking-wider text-blue-600 uppercase">
                A social media for learners
              </p>
              <h4 class="mt-4 text-4xl font-bold text-black lg:mt-8 sm:text-6xl xl:text-6xl">
                Latest Technologies for Unmatched Performance and Innovation
              </h4>
              <p class="mt-4 text-base text-black lg:mt-8 sm:text-xl">
                Grow your career fast with right mentor.
              </p>

              {/* <p class="mt-5 text-gray-600">
                  Already joined us?{" "}
                  <a
                    href="#"
                    title=""
                    class="text-black transition-all duration-200 hover:underline"
                  >
                    Log in
                  </a>
                </p> */}
            </div>

            <div>
              <img class="w-full" src={Technology2} alt="" />
            </div>
          </div>
        </div>
      </section>

      <section class="py-10 bg-gray-50 sm:py-16 lg:py-24">
        <div class="max-w-6xl px-4 mx-auto sm:px-6 lg:px-8">
          <div class="grid items-center grid-cols-1 lg:items-stretch md:grid-cols-2 gap-y-8 gap-x-12 xl:gap-x-20">
            <div class="relative">
              <div class="aspect-w-4 aspect-h-3">
                <img
                  class="object-cover w-full h-full"
                  src={service14}
                  alt=""
                />
              </div>

              <div class="absolute inset-0 flex items-center justify-center">
                <div class="flex items-center justify-center rounded-full w-28 h-28 bg-white/20"></div>
              </div>
            </div>

            <div class="flex flex-col justify-between md:py-5">
              <blockquote>
                <p class="text-2xl leading-relaxed text-black">
                  A full-stack developer is proficient in both front-end and
                  back-end technologies, capable of handling server-side and
                  client-side programming. They integrate databases, APIs, and
                  user interfaces to create complete web applications, combining
                  skills in languages like JavaScript, Python, and frameworks
                  such as React and Node.js.
                </p>
              </blockquote>
            </div>
          </div>
        </div>
      </section>

      <section class="py-10 bg-white sm:py-16 lg:py-24">
        <div class="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8">
          <div class="mx-auto text-left md:max-w-lg lg:max-w-2xl md:text-center">
            <h2 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl lg:leading-tight">
              Database Developer
              <span class="relative inline-block">
                <span class="absolute inline-block w-full h-2 bg-yellow-300 bottom-1.5"></span>
                <span class="relative"></span>
              </span>
            </h2>
          </div>

          <div class="grid grid-cols-1 mt-8 md:mt-20 gap-y-6 md:grid-cols-2 gap-x-10">
            <div>
              <img class="w-full mx-auto sm:max-w-xs" src={service13} alt="" />
            </div>

            <div>
              <h3 class="text-lg font-semibold text-gray-900">Database :</h3>
              <p class="mt-4 text-lg text-gray-900">
                A database is a structured collection of data organized to
                facilitate easy access, management, and update. It serves as a
                central repository for storing and retrieving information
                efficiently. Database management systems (DBMS) like MySQL,
                PostgreSQL, and MongoDB provide interfaces to interact with
                databases. They ensure data integrity, security, and
                scalability, crucial for applications ranging from simple data
                storage to complex enterprise systems and big data analytics.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* services section start */}
      <section className="py-10 bg-gradient-to-r from-gray-100 to-gray-200 sm:py-16 lg:py-24">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="max-w-2xl mx-auto text-left sm:text-center">
            <h6 className="text-blue-900 fw-bold text-3xl">
              Start Creating Today With Our Web Application Development Services
            </h6>
          </div>
          <div className="  grid grid-cols-1 gap-6 mt-8 sm:mt-12 xl:mt-20 sm:grid-cols-2 lg:grid-cols-3 lg:gap-8 xl:gap-14">
            <div className="services transition duration-300 transform shadow-lg hover:scale-105">
              <div className="py-8 px-9">
                <center>
                  <img className="d-block w-50" src={service} alt={service} />
                </center>
                <p className="text-lg font-bold text-center">
                  Python Developer
                </p>
                <p className="mt-6 text-base text-justify">
                  Arc Solutions's professional Python developers have both the
                  expertise and experience to develop innovative, interactive,
                  feature-packed and high-functioning Python-based applications
                  <br></br>
                  
                </p>
              </div>
            </div>

            <div className="services transition duration-300 transform shadow-lg hover:scale-105">
              <div className="py-8 px-9">
                <center>
                  <img className="d-block w-50" src={service1} alt={service1} />
                </center>
                <p className="text-lg font-bold">Web Development</p>
                <p className="mt-6 text-base text-justify">
                  At Arc Solutions, our front-end as well as the back-end
                  website programmers are highly trained coding experts. Our
                  development team is always acquiring new tricks to stay on the
                  top of modern programming practices and concepts. Whether its
                  PHP, Ajax or .Net, Reactjs Nodejs, Wordpress. our development
                  team has the skills to develop any website.
                  <br />
                 
                </p>
              </div>
            </div>

            <div className="services transition duration-300 transform shadow-lg hover:scale-105">
              <div className="py-8 px-9">
                <center>
                  <img className="d-block w-50" src={service2} alt={service2} />
                </center>
                <p className="text-lg font-bold">PHP Development</p>
                <p className="mt-6 text-base text-justify">
                  PHP is a popular server-side scripting language primarily used
                  for web development, enabling dynamic content and database
                  interaction. It's known for its ease of use and flexibility.
                  <br />
                  
                </p>
              </div>
            </div>

            <div className="services transition duration-300 transform shadow-lg hover:scale-105">
              <div className="py-8 px-9">
                <center>
                  <img className="d-block w-50" src={service3} alt={service3} />
                </center>
                <p className="text-lg font-bold">Software Development</p>
                <p className="mt-6 text-base text-justify">
                  The customized services offered by Arc Solutions will help you
                  build apps that are a good fit for your existing
                  infrastructure and gel well with your environment. We provide
                  high quality software that meets your needs, whether you
                  require intermediary blocks built into your system or require
                  an end-to-end solution.
                  <br />
                  
                </p>
              </div>
            </div>

            <div className="services transition duration-300 transform shadow-lg hover:scale-105">
              <div className="py-8 px-9">
                <center>
                  <img className="d-block w-50" src={service4} alt={service4} />
                </center>
                <p className="text-lg font-bold">Java Developer</p>
                <p className="mt-6 text-base text-justify">
                  Java is a versatile, object-oriented programming language
                  known for its portability across platforms. Widely used in
                  web, mobile, and enterprise applications, Java emphasizes
                  security and performance. Its robust ecosystem and extensive
                  libraries make it a popular choice for developers worldwide.
                  <br />
                  
                </p>
              </div>
            </div>

            <div className="services transition duration-300 transform shadow-lg hover:scale-105">
              <div className="py-8 px-9">
                <center>
                  <img className="d-block w-50" src={service5} alt={service5} />
                </center>
                <p className="text-lg font-bold">API Development</p>
                <p className="mt-1 text-500 text-justify">
                  Our custom web application development company ensures
                  seamless communication and integration. Our API development
                  services streamline processes, enhance functionality, and
                  create a unified user experience.
                  <br />
                 
                </p>
              </div>
            </div>
            <div className="services transition duration-300 transform shadow-lg hover:scale-105">
              <div className="py-8 px-9">
                <center>
                  <img
                    className="d-block w-50"
                    src={service10}
                    alt={service10}
                  />
                </center>
                <p className="text-lg font-bold">Redux Development</p>
                <p className="mt-1 text-500 text-justify">
                  Redux is a predictable state container for JavaScript
                  applications, commonly used with React. It centralizes
                  application state and logic, making debugging and testing
                  easier. With a strict unidirectional data flow, Redux enhances
                  maintainability and predictability in complex applications.
                  <br />
                  
                </p>
              </div>
            </div>
            <div className="services transition duration-300 transform shadow-lg hover:scale-105">
              <div className="py-8 px-9">
                <center>
                  <img
                    className="d-block w-50"
                    src={service11}
                    alt={service11}
                  />
                </center>
                <p className="text-lg font-bold">
                  HTML, CSS, Javascript Development
                </p>
                <p className="mt-1 text-500 text-justify">
                  HTML, CSS, and JavaScript form the core technologies of the
                  web. HTML provides structure, CSS styles elements, and
                  JavaScript adds interactivity. Together, they create dynamic
                  and visually appealing websites, essential for modern web
                  development.
                  <br />
                 
                </p>
              </div>
            </div>
            <div className="services transition duration-300 transform shadow-lg hover:scale-105">
              <div className="py-8 px-9">
                <center>
                  <img
                    className="d-block w-50"
                    src={service12}
                    alt={service12}
                  />
                </center>
                <p className="text-lg font-bold">Laravel Development</p>
                <p className="mt-1 text-500 text-justify">
                  HTML, CSS, and JavaScript form the core technologies of the
                  web. HTML provides structure, CSS styles elements, and
                  JavaScript adds interactivity. Together, they create dynamic
                  and visually appealing websites, essential for modern web
                  development.
                  <br />
                  
                
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* services section end */}

      <section class="py-10 bg-gray-50 sm:py-16 lg:py-24">
        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div class="grid items-center grid-cols-2 gap-10 sm:gap-y-16 sm:grid-cols-3 xl:grid-cols-6">
            <div>
              <img
                class="object-contain w-auto mx-auto h-14"
                src={service6}
                alt=""
              />
            </div>

            <div>
              <img
                class="object-contain w-auto mx-auto h-14"
                src={service7}
                alt=""
              />
            </div>

            <div>
              <img
                class="object-contain w-auto mx-auto h-14"
                src={service8}
                alt=""
              />
            </div>

            <div>
              <img
                class="object-contain w-auto mx-auto h-14"
                src={service9}
                alt=""
              />
            </div>

            <div>
              <img
                class="object-contain w-auto mx-auto h-14"
                src={service10}
                alt=""
              />
            </div>

            <div>
              <img
                class="object-contain w-auto mx-auto h-14"
                src={service11}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Technology;
