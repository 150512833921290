import { React } from "react";
import ppc2 from "../img/ppc1.jpg";
import { Footer } from "./Footer";
import { Helmet } from "react-helmet-async"; // Import Helmet
// images for services
import ppc1 from "../img/ppc1.png";
import ppc3 from "../img/ppc3.png";
import service2 from "../img/ppc4.png";
import service3 from "../img/ppc5.png";
import service4 from "../img/pp6.png";
import service5 from "../img/ppc7.png";
import service6 from "../img/ppc8.png";
import service7 from "../img/ppc9.png";
import service8 from "../img/ppc10.png";

function PPC() {
  return (
    <>
     <Helmet>
        <title>Arc Solutions || PPC </title>
        <meta name="description" content="Learn more about Arc Solutions, a leading IT Solution provider." />
        <meta name="keywords" content="Arc Solutions, IT services, Web Development, ERP Solutions, Software Development" />
        <meta name="author" content="Arc Solutions" />
      </Helmet>
      {/* Banner Job Portal Start */}
      <img
        className="d-block w-100"
        src={ppc2}
        alt="Job_Portal1"
        style={{ height: "100%", objectFit: "cover" }}
      />
      {/* Banner Job Portal End */}

      <div className="row">
        <div className="col-md-12">
          <h2 className="mt-5 mx-4">Our PPC Advertising Services in India</h2>
          <p className="mt-2 mx-4 .fs-4">
            Here’s a list of our pay-per-click marketing services in India:
          </p>
        </div>
      </div>

      {/* services section start */}
      <section className="py-10 bg-gradient-to-r from-gray-100 to-gray-200 sm:py-16 lg:py-24">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="grid grid-cols-1 gap-6 mt-8 sm:mt-12 xl:mt-20 sm:grid-cols-2 lg:grid-cols-3 lg:gap-8 xl:gap-14">
            <div className="services transition duration-300 transform shadow-lg hover:scale-105">
              <div className="py-8 px-9">
                <center>
                  <img className="d-block w-50" src={ppc1} alt={ppc1} />
                </center>
                <p className="p text-lg font-bold text-center mt-5">
                  Search Advertising
                </p>
                <p className="mt-6 text-base text-justify">
                  Search ads appear to prospects searching for products/services
                  you offer, and generate high-quality leads.
                  <br></br>
                  <center>
                    <button className="text-primary mt-2 bg-blue-900 text-white mt-5">
                      <a
                        href="#"
                        className="btn1 text-white text-center text-decoration-none p-2 rounded-full"
                      >
                        Read More
                      </a>
                    </button>
                  </center>
                </p>
              </div>
            </div>

            <div className="services transition duration-300 transform shadow-lg hover:scale-105">
              <div className="py-8 px-9">
                <center>
                  <img className="d-block w-50" src={ppc3} alt={ppc3} />
                </center>
                <p className="p text-lg font-bold mt-4">Display Advertising</p>
                <p className="mt-6 text-base text-justify mt-5">
                  Display ads are good for branding / awareness and remarketing
                  through images, banners, creative copies, etc., that appear on
                  display ad networks.
                  <br />
                  <button className="mt-5 btn1 text-primary mt-2 bg-blue-900 text-white">
                    <a
                      href=""
                      className="text-white text-decoration-none p-2 rounded-full"
                    >
                      Read More
                    </a>
                  </button>
                </p>
              </div>
            </div>

            <div className="services transition duration-300 transform shadow-lg hover:scale-105">
              <div className="py-8 px-9">
                <center>
                  <img className="d-block w-50" src={service2} alt={service2} />
                </center>
                <p className="p text-lg font-bold mt-5">
                  Social Media Advertising
                </p>
                <p className="mt-6 text-base text-justify">
                  Social media platforms offer excellent targeting capabilities,
                  which bolsters your overall marketing strategy and even drives
                  sales.
                  <br />
                  <button className="text-primary mt-2 bg-blue-900 text-white mt-5">
                    <a
                      href=""
                      className="btn1 text-white text-decoration-none p-2 rounded-full"
                    >
                      Read More
                    </a>
                  </button>
                </p>
              </div>
            </div>

            <div className="services transition duration-300 transform shadow-lg hover:scale-105">
              <div className="py-8 px-9">
                <center>
                  <img className="d-block w-50" src={service3} alt={service3} />
                </center>
                <p className="p text-lg font-bold mt-5">Remarketing</p>
                <p className="mt-6 text-base text-justify">
                  Remarketing ads appear to people who have already visited your
                  website and shown interest in making a purchase.
                  <br />
                  <button className="text-primary mt-2 bg-blue-900 text-white mt-5">
                    <a
                      href=""
                      className="btn1 text-white text-decoration-none p-2 rounded-full"
                    >
                      Read More
                    </a>
                  </button>
                </p>
              </div>
            </div>

            <div className="services transition duration-300 transform shadow-lg hover:scale-105">
              <div className="py-8 px-9">
                <center>
                  <img className="d-block w-50" src={service4} alt={service4} />
                </center>
                <p className="p text-lg font-bold mt-4">Google Shopping Ads</p>
                <p className="mt-6 text-base text-justify mt-4">
                  Google Shopping Ads are ideal for online retail and ecommerce
                  businesses; these are shown in a carousel above natural search
                  results, and shopping section, GDN. etc.
                  <br />
                  <button className="text-primary mt-2 bg-blue-900 text-white mt-5">
                    <a
                      href=""
                      className="btn1 text-white text-decoration-none p-2 rounded-full"
                    >
                      Read More
                    </a>
                  </button>
                </p>
              </div>
            </div>

            <div className="services transition duration-300 transform shadow-lg hover:scale-105">
              <div className="py-8 px-9">
                <center>
                  <img className="d-block w-50" src={service5} alt={service5} />
                </center>
                <p className="p text-lg font-bold mt-4">Explore PPC Packages</p>
                <p className="mt-1 text-500 text-justify mt-4">
                  Check our PPC packages to choose a service plan suited to your
                  business objectives.
                  <br />
                  <button className="text-primary mt-2 bg-blue-900 text-white mt-5">
                    <a
                      href=""
                      className="btn1 text-white text-decoration-none p-2 rounded-full"
                    >
                      Read More
                    </a>
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* services section end */}

      <div className="row">
        <div className="col-md-12">
          <div className="container-fluid mt-2 mx-3">
            <h3>Pay-Per-Click Marketing Challenges We Help You Overcome</h3>
            <p>
              Requiring thoughtful execution of the campaigns to manage the
              budget and drive results, pay-per-click marketing has several
              challenges.
            </p>
            <div class="flex items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 shrink-0 decoration-sky-500"
                fill="none"
                viewBox="0 0 16 16"
              >
                <circle
                  cx="8"
                  cy="8"
                  r="8"
                  fill="currentColor"
                  class="text-gray-300"
                />
                <circle
                  cx="8"
                  cy="8"
                  r="3"
                  fill="currentColor"
                  class="text-gray-500"
                />
              </svg>

              <span class="text-gray-600 text-black">
                Acquiring the hot ad spaces without exhausting the budget
              </span>
            </div>
            <div class="flex items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 shrink-0"
                fill="none"
                viewBox="0 0 16 16"
              >
                <circle
                  cx="8"
                  cy="8"
                  r="8"
                  fill="currentColor"
                  class="text-gray-300"
                />
                <circle
                  cx="8"
                  cy="8"
                  r="3"
                  fill="currentColor"
                  class="text-gray-500"
                />
              </svg>

              <span class="text-gray-600 text-black">
                Publishing relevant ads without incurring ad fatigue
              </span>
            </div>
            <div class="flex items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 shrink-0"
                fill="none"
                viewBox="0 0 16 16"
              >
                <circle
                  cx="8"
                  cy="8"
                  r="8"
                  fill="currentColor"
                  class="text-gray-300"
                />
                <circle
                  cx="8"
                  cy="8"
                  r="3"
                  fill="currentColor"
                  class="text-gray-500"
                />
              </svg>

              <span class="text-gray-600 text-black">
                Driving targeted traffic and getting meaningful clicks on the
                ads
              </span>
            </div>
            <div class="flex items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 shrink-0"
                fill="none"
                viewBox="0 0 16 16"
              >
                <circle
                  cx="8"
                  cy="8"
                  r="8"
                  fill="currentColor"
                  class="text-gray-300"
                />
                <circle
                  cx="8"
                  cy="8"
                  r="3"
                  fill="currentColor"
                  class="text-gray-500"
                />
              </svg>

              <span class="text-gray-600 text-black">
                Fully utilizing the limited ad space and creating impactful
                copies
              </span>
            </div>
            <div class="flex items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 shrink-0"
                fill="none"
                viewBox="0 0 16 16"
              >
                <circle
                  cx="8"
                  cy="8"
                  r="8"
                  fill="currentColor"
                  class="text-gray-300"
                />
                <circle
                  cx="8"
                  cy="8"
                  r="3"
                  fill="currentColor"
                  class="text-gray-500"
                />
              </svg>

              <span class="text-gray-600 text-black">
                Creating landing pages that have a minimal bounce rate and
                facilitate higher conversions
              </span>
            </div>
            <div class="flex items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 shrink-0 text-indigo-50"
                fill="none"
                viewBox="0 0 16 16"
              >
                <circle
                  cx="8"
                  cy="8"
                  r="8"
                  fill="currentColor"
                  class="text-gray-300"
                />
                <circle
                  cx="8"
                  cy="8"
                  r="3"
                  fill="currentColor"
                  class="text-gray-500"
                />
              </svg>

              <span class="text-gray-600 text-black">
                Continuously updating the ads with the latest trends and changes
                in the market
              </span>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />

      {/* WHY CHOOSE TECHMAGNATE FOR PPC SERVICES IN INDIA Start */}
      <div class="flex justify-center bg-gradient-to-r from-blue-700 via-blue-800 to-cyan-600 ">
        <div class="flex flex-col justify-center  my-10 w-[90%] space-y-28 lg:space-y-24 max-w-7xl  ">
          <div class="flex flex-col justify-center items-center text-center">
            <div class="text-xl md:text-3xl text-white font-bold uppercase">
              Why Choose Techmagnate For PPC Services in India
            </div>
          </div>
          <div class="flex flex-col justify-center items-center lg:flex-row space-y-28 lg:space-y-0  lg:space-x-10">
            <div class="bg-white shadow-lg flex flex-col justify-center rounded-lg items-center py-4 h-100 md:w-[80%] lg:w-fit">
              <div class="font-semibold text-2xl">Unmatched Portfolio</div>
              <p class="text-center text-lg p-5">
                We have managed PPC campaigns for brands across industries such
                as Education, BFSI, E-commerce, and Healthcare.
              </p>

              <center>
                <button className="text-primary mt-2 bg-blue-900 text-white">
                  <a
                    href="#"
                    className="btn1 text-white text-center text-decoration-none p-2 rounded-full"
                  >
                    PORTFOLIO
                  </a>
                </button>
              </center>
            </div>

            <div class="bg-white shadow-lg flex flex-col justify-center rounded-lg items-center py-4 h-100 md:w-[80%] lg:w-fit">
              <div class="font-semibold text-2xl">Proven Expertise</div>
              <p class="text-center text-lg p-5">
                We’re a Google Premier Partner and have professional search
                marketers well-versed in executing result-driven PPC campaigns
                for your brand.
              </p>
              <center>
                <button className="text-primary mt-2 bg-blue-900 text-white mt-5">
                  <a
                    href="#"
                    className="btn1 text-white text-center text-decoration-none p-2 rounded-full"
                  >
                    OUR TEAM
                  </a>
                </button>
              </center>
            </div>
            <div class="bg-white shadow-lg flex flex-col justify-center rounded-lg items-center py-4 h-100 md:w-[80%] lg:w-fit">
              <div class="font-semibold text-2xl">Performance-Driven</div>
              <p class="text-center text-lg p-5">
                We delivered digital success to our clients by registering an
                avg. growth of 115% in conversions and a reduction of 17% in
                CPA.
              </p>
              <center>
                <button className="text-primary mt-2 bg-blue-900 text-white mt-5">
                  <a
                    href="#"
                    className="btn1 text-white text-center text-decoration-none p-2 rounded-full"
                  >
                    DIGITAL REPORT CARD
                  </a>
                </button>
              </center>
            </div>
          </div>
        </div>
      </div>
      <script
        type="module"
        src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.esm.js"
      ></script>
      <script
        nomodule
        src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.js"
      ></script>
      {/* WHY CHOOSE TECHMAGNATE FOR PPC SERVICES IN INDIA END */}
      

      {/* Our Blueprint Start */}
      <section class="text-gray-600 body-font">
        <div class="container px-5 py-24 mx-auto">
          <div class="text-center mb-20">
            <h1 class="sm:text-3xl text-2xl font-medium title-font text-indigo-500 mb-4">
              Our Blueprint for Offering Pay-Per-Click Advertising Services
            </h1>
            <p>
              Being a Google premier partner, we’re a data-led paid marketing
              services provider, i.e., whether it is branding or lead
              generation, our clients can measure the delivered growth with data
              metrics.
            </p>
            <div class="flex mt-6 justify-center">
              <div class="w-16 h-1 rounded-full bg-indigo-500 inline-flex"></div>
            </div>
          </div>

          <div class="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
            <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
              <img src={service6} alt="" />
              <div className="flex-grow">
                <h2 className="text-gray-900 text-lg title-font font-medium mb-3 mt-3">
                  1. Strategic Planning & Execution
                </h2>
                <p class="leading-relaxed text-base">
                  Once done, we refine the executed paid campaigns by optimizing
                  the landing pages, ad copies, CTA placement, bidding strategy,
                  etc. The idea is to reduce the CPC and CPA while maximizing
                  the results, and our strategy ensures that brands get a better
                  ROAS while driving excellent results.
                </p>
              </div>
            </div>

            <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
              <img src={service7} alt="" />
              <div className="flex-grow">
                <h2 className="text-gray-900 text-lg title-font font-medium mb-3 mt-3">
                  2. Paid Campaign Refinement
                </h2>
                <p class="leading-relaxed text-base">
                  Once done, we refine the executed paid campaigns by optimizing
                  the landing pages, ad copies, CTA placement, bidding strategy,
                  etc. The idea is to reduce the CPC and CPA while maximizing
                  the results, and our strategy ensures that brands get a better
                  ROAS while driving excellent results.
                </p>
              </div>
            </div>

            <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
              <img src={service8} alt="" />
              <div className="flex-grow">
                <h2 className="text-gray-900 text-lg title-font font-medium mb-3 mt-3">
                  3. Performance Reporting & Improvement
                </h2>
                <p class="leading-relaxed text-base">
                  The final step is to generate performance reports and share
                  them with the clients frequently. The reports have a dynamic
                  dashboard containing all the crucial details such as CTR,
                  ROAS, Traffic, Impressions, CPL, CPA, etc. Based on the
                  findings, we further optimize the campaign and capitalize on
                  the high-performing areas.
                </p>
              </div>
            </div>
          </div>
        </div>
        
      </section>
      {/* Our Blueprint End */}
      <Footer />
    </>
  );
}

export default PPC;
