import React from "react";
import { Footer } from "../component/Footer";
import country from "../img/word.jpg";
import { Helmet } from "react-helmet-async"; // Import Helmet
const International = () => {
  return (
    <>
    <Helmet>
        <title>Arc Solutions || International Contact</title>
        <meta name="description" content="Learn more about Arc Solutions, a leading IT Solution provider." />
        <meta name="keywords" content="Arc Solutions, IT services, Web Development, ERP Solutions, Software Development" />
        <meta name="author" content="Arc Solutions" />
      </Helmet>
      {/* Banner Job Portal Start */}
      <img
        className="d-block w-100"
        src={country}
        alt="Job_Portal1"
        style={{ height: "100%", objectFit: "cover" }}
      />
      {/* Banner Job Portal End */}

      <div className="bg-white py-6 sm:py-8 lg:py-12">
        <div className="mx-auto max-w-screen-xl px-4 md:px-8">
          <h2 className="mb-8 text-center text-2xl font-bold md:mb-12 lg:text-3xl">
            International
          </h2>

          <div className="grid gap-4 md:grid-cols-3 md:gap-8">
            {/* <!-- card 1 --> */}
            <div className="flex flex-col items-center gap-4 rounded-lg bg-orange-500 hover:bg-blue-900 px-8 py-6 md:gap-6 transition-transform duration-500 hover:scale-105 shadow-lg hover:shadow-xl">
              <div className="max-w-md text-center font-bold text-white lg:text-lg text-2xl">
                Asia
                <br />
                India
              </div>
              <div className="flex flex-col items-center gap-2 sm:flex-row md:gap-3">
                <div>
                  <div className="text-center font-bold text-indigo-50 sm:text-left md:text-base slide-text text-2xl">
                    Delhi
                  </div>
                  <br />
                  <div className="text-center text-sm font-bold text-indigo-50 sm:text-left md:text-base slide-text">
                    By-Sales &nbsp;&nbsp; : sales@arcsolutions.org
                  </div>
                  <div className="text-center text-sm font-bold text-indigo-50 sm:text-left md:text-base slide-text">
                    By-Support &nbsp; : service@arcsolutions.org
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- card 2 --> */}
            <div className="flex flex-col items-center gap-4 rounded-lg bg-orange-500 hover:bg-blue-900 px-8 py-6 md:gap-6 transition-transform duration-500 hover:scale-105 shadow-lg hover:shadow-xl">
              <div className="max-w-md text-center text-white lg:text-lg font-bold text-2xl">
                North America
                <br />
                Canada
              </div>
              <div className="flex flex-col items-center gap-2 sm:flex-row md:gap-3">
                <div>
                  <div className="text-center text-sm font-bold text-indigo-50 sm:text-left md:text-base slide-text text-2xl">
                    British Columbia
                  </div>
                  <br />
                  <div className="text-center text-sm font-bold text-indigo-50 sm:text-left md:text-base slide-text">
                    By-Sales &nbsp;&nbsp; : sales@arcsolutions.org
                  </div>
                  <div className="text-center text-sm font-bold text-indigo-50 sm:text-left md:text-base slide-text">
                    By-Support &nbsp; : service@arcsolutions.org
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- card 3 --> */}
            <div className="flex flex-col items-center gap-4 rounded-lg bg-orange-500 hover:bg-blue-900 px-8 py-6 md:gap-6 transition-transform duration-500 hover:scale-105 shadow-lg hover:shadow-xl">
              <div className="max-w-md text-center text-white lg:text-lg text-2xl font-bold">
                Europe
                <br />
                Germany
              </div>
              <div className="flex flex-col items-center gap-2 sm:flex-row md:gap-3">
                <div>
                  <div className="text-center text-sm font-bold text-indigo-50 sm:text-left md:text-base slide-text text-2xl">
                    Berlin
                  </div>
                  <br />
                  <div className="text-center text-sm font-bold text-indigo-50 sm:text-left md:text-base slide-text">
                    By-Sales &nbsp;&nbsp; : sales@arcsolutions.org
                  </div>
                  <div className="text-center text-sm font-bold text-indigo-50 sm:text-left md:text-base slide-text">
                    By-Support &nbsp; : service@arcsolutions.org
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- card 4 --> */}
            <div className="flex flex-col items-center gap-4 rounded-lg bg-orange-500 hover:bg-blue-900 px-8 py-6 md:gap-6 transition-transform duration-500 hover:scale-105 shadow-lg hover:shadow-xl">
              <div className="max-w-md text-center text-white lg:text-lg text-2xl font-bold">
                South America
                <br />
                Brazil
              </div>
              <div className="flex flex-col items-center gap-2 sm:flex-row md:gap-3">
                <div>
                  <div className="text-center text-sm font-bold text-indigo-50 sm:text-left md:text-base slide-text text-2xl">
                    São Paulo
                  </div>
                  <br />
                  <div className="text-center text-sm font-bold text-indigo-50 sm:text-left md:text-base slide-text">
                    By-Sales &nbsp;&nbsp; : sales@arcsolutions.org
                  </div>
                  <div className="text-center text-sm font-bold text-indigo-50 sm:text-left md:text-base slide-text">
                    By-Support &nbsp; : service@arcsolutions.org
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- card 5 --> */}
            <div className="flex flex-col items-center gap-4 rounded-lg bg-orange-500 hover:bg-blue-900 px-8 py-6 md:gap-6 transition-transform duration-500 hover:scale-105 shadow-lg hover:shadow-xl">
              <div className="max-w-md text-center text-white lg:text-lg text-2xl font-bold">
                Africa
                <br />
                Nigeria
              </div>
              <div className="flex flex-col items-center gap-2 sm:flex-row md:gap-3">
                <div>
                  <div className="text-center text-sm font-bold text-indigo-50 sm:text-left md:text-base slide-text text-2xl">
                    Lagos
                  </div>
                  <br />
                  <div className="text-center text-sm font-bold text-indigo-50 sm:text-left md:text-base slide-text">
                    By-Sales &nbsp;&nbsp; : sales@arcsolutions.org
                  </div>
                  <div className="text-center text-sm font-bold text-indigo-50 sm:text-left md:text-base slide-text">
                    By-Support &nbsp; : service@arcsolutions.org
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- card 6 --> */}
            <div className="flex flex-col items-center gap-4 rounded-lg bg-orange-500 hover:bg-blue-900 px-8 py-6 md:gap-6 transition-transform duration-500 hover:scale-105 shadow-lg hover:shadow-xl">
              <div className="max-w-md text-center text-white lg:text-lg text-2xl font-bold">
                Australia
                <br />
                Australia
              </div>
              <div className="flex flex-col items-center gap-2 sm:flex-row md:gap-3">
                <div>
                  <div className="text-center text-sm font-bold text-indigo-50 sm:text-left md:text-base slide-text text-2xl">
                    Sydney
                  </div>
                  <br />
                  <div className="text-center text-sm font-bold text-indigo-50 sm:text-left md:text-base slide-text">
                    By-Sales &nbsp;&nbsp; : sales@arcsolutions.org
                  </div>
                  <div className="text-center text-sm font-bold text-indigo-50 sm:text-left md:text-base slide-text">
                    By-Support &nbsp; : service@arcsolutions.org
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default International;
