import { React } from "react";
import { Helmet } from "react-helmet-async"; // Import Helmet
import Job_Portal1 from "../img/Job_portal6.jpg";
import Job_Portal2 from "../img/Job_portal3.png";
import job_portal4 from "../img/Portal5.jpg";
import service from "../img/services-icons.png";
import { Footer } from "../component/Footer";

function Job_Portal() {
  return (
    
    <div>
      <Helmet>
        <title>Arc Solutions || Job Portal</title>
        <meta name="description" content="This is a description of the page content." />
        <meta name="keywords" content="React, carousel, example, meta tags" />
        <meta name="author" content="Your Name or Company" />
        <meta property="og:title" content="Your Page Title" />
        <meta property="og:description" content="This is a description of the page content." />
        <meta property="og:image" content="URL_to_your_image" />
        <meta property="og:url" content="Your_page_URL" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Your Page Title" />
        <meta name="twitter:description" content="This is a description of the page content." />
        <meta name="twitter:image" content="URL_to_your_image" />
      </Helmet>
      {/* Banner Job Portal Start */}
      <img
        className="d-block w-100"
        src={Job_Portal1}
        alt="Job_Portal1"
        style={{ height: "100%", objectFit: "cover" }}
      />
      {/* Banner Job Portal End */}

      <div class="bg-white py-6 sm:py-8 lg:py-12">
  <div class="mx-auto max-w-screen-xl px-4 md:px-8">
    <div class="grid gap-8 md:grid-cols-2 lg:gap-12 items-center">
      <div>
        <div class="h-64 overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-auto">
          <center>
            <img
              src={Job_Portal2}
              style={{ objectFit: "cover" }}
              loading="lazy"
              alt="Photo by Martin Sanchez"
              class="w-50 object-cover object-center"
            />
          </center>   
        </div>
      </div>

      <div class="md:pt-8">
        <p class="text-center font-bold text-blue-900 md:text-left">
          Our Job Portal
        </p>

        <h1 class="mb-4 text-center text-2xl font-bold text-black sm:text-3xl md:mb-6 md:text-left">
          Welcome to Job Portal
        </h1>

        <p class="mb-6 text-black sm:text-lg md:mb-8">
          Arc Solution is a leading job portal company dedicated to connecting job seekers with their ideal careers. Utilizing advanced technology and a user-friendly interface, Arc Solution streamlines the job search process, providing personalized job recommendations and valuable resources. Employers also benefit from Arc Solution’s extensive database of qualified candidates, making recruitment efficient and effective.
          <br />
          <br />
        </p>
      </div>
    </div>
  </div>
</div>


      {/* Online Job Portal Start */}
      <div class="bg-white py-6 sm:py-8 lg:py-12">
        <div class="mx-auto max-w-screen-xl px-4 md:px-8">
          <div class="grid gap-8 md:grid-cols-2 lg:gap-12">
            <div class="md:pt-8">
              <h1 class="mb-4 text-center text-2xl font-bold text-gray-800 sm:text-3xl md:mb-6 md:text-left">
                Online Job Portal
              </h1>

              <div class="flex items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 shrink-0"
                  fill="none"
                  viewBox="0 0 16 16"
                >
                  <circle
                    cx="8"
                    cy="8"
                    r="8"
                    fill="currentColor"
                    class="text-gray-300"
                  />
                  <circle
                    cx="8"
                    cy="8"
                    r="3"
                    fill="currentColor"
                    class="text-gray-500"
                  />
                </svg>

                <span class="text-gray-600 text-black">
                  HTML: At Arc Solutions, HTML forms the foundation of our web
                  development, ensuring structured and accessible content
                  delivery across all devices.
                </span>
              </div>
              <br />
              <div class="flex items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 shrink-0"
                  fill="none"
                  viewBox="0 0 16 16"
                >
                  <circle
                    cx="8"
                    cy="8"
                    r="8"
                    fill="currentColor"
                    class="text-gray-300"
                  />
                  <circle
                    cx="8"
                    cy="8"
                    r="3"
                    fill="currentColor"
                    class="text-gray-500"
                  />
                </svg>

                <span class="text-gray-600 text-black">
                  CSS: Arc Solutions utilizes CSS to style and design visually
                  appealing interfaces, ensuring consistent branding and
                  intuitive user interfaces across applications.
                </span>
              </div>
              <br />
              <div class="flex items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 shrink-0"
                  fill="none"
                  viewBox="0 0 16 16"
                >
                  <circle
                    cx="8"
                    cy="8"
                    r="8"
                    fill="currentColor"
                    class="text-gray-300"
                  />
                  <circle
                    cx="8"
                    cy="8"
                    r="3"
                    fill="currentColor"
                    class="text-gray-500"
                  />
                </svg>

                <span class="text-gray-600 text-black">
                  JavaScript: Powering interactivity on Arc Solutions’
                  platforms, JavaScript enhances user engagement through dynamic
                  content updates and seamless client-side functionalities.
                </span>
              </div>
              <br />
              <div class="flex items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 shrink-0"
                  fill="none"
                  viewBox="0 0 16 16"
                >
                  <circle
                    cx="8"
                    cy="8"
                    r="8"
                    fill="currentColor"
                    class="text-gray-300"
                  />
                  <circle
                    cx="8"
                    cy="8"
                    r="3"
                    fill="currentColor"
                    class="text-gray-500"
                  />
                </svg>

                <span class="text-gray-600 text-black">
                  Bootstrap: Employing Bootstrap, Arc Solutions accelerates
                  development with its responsive grid system and pre-styled
                  components, ensuring mobile-first designs.
                </span>
              </div>
              <br />
              <div class="flex items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 shrink-0"
                  fill="none"
                  viewBox="0 0 16 16"
                >
                  <circle
                    cx="8"
                    cy="8"
                    r="8"
                    fill="currentColor"
                    class="text-gray-300"
                  />
                  <circle
                    cx="8"
                    cy="8"
                    r="3"
                    fill="currentColor"
                    class="text-gray-500"
                  />
                </svg>

                <span class="text-gray-600 text-black">
                  PHP: Backend functionality at Arc Solutions is robustly
                  powered by PHP, facilitating server-side scripting for dynamic
                  web applications and seamless database interactions.
                </span>
              </div>
              <br />
              <div class="flex items-center gap-2 text-black">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 shrink-0"
                  fill="none"
                  viewBox="0 0 16 16"
                >
                  <circle
                    cx="8"
                    cy="8"
                    r="8"
                    fill="currentColor"
                    class="text-gray-300"
                  />
                  <circle
                    cx="8"
                    cy="8"
                    r="3"
                    fill="currentColor"
                    class="text-gray-500"
                  />
                </svg>

                <span class="text-gray-600 text-black">
                  React: Arc Solutions leverages React to build dynamic and
                  responsive user interfaces, enhancing user experience with
                  fast rendering and component reusability.
                </span>
              </div>
            </div>
            <div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <div className="h-64 overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-auto hover:shadow-xl">
    <img
        src={job_portal4}
        style={{ height: "420px", objectFit: "cover" }}
        loading="lazy"
        alt="Photo by Martin Sanchez"
        className="h-full w-full object-cover object-center transform scale-100 hover:scale-105 transition duration-300"
    />
</div>
            </div>
          </div>
        </div>
      </div>
      {/* Online Job Portal End */}


        {/* Connect With App Start */}
      <section class=" overflow-hidden bg-gray-100 sm:pt-16">
        <div class="mx-auto sm:px-6 lg:px-6 max-w-7xl">
          <div class="max-w-2xl mx-auto text-center">
            <h2 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">
              Connect with all apps
            </h2>
          </div>
        </div>
        <center><img
              src={service}
              style={{ objectFit: "cover" }}
              loading="lazy"
              alt="Photo by Martin Sanchez"
              class="w-50 mt-5 object-cover object-center"
            /></center>
       
      </section>
      {/* Connect With App End */}

      <Footer />
    </div>
  );
}

export default Job_Portal;
