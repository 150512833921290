import React, { useState } from "react";
import Slider from "react-slick";
import { Helmet } from "react-helmet-async"; // Import Helmet
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Footer } from "../component/Footer";

// Additional images for cards3
import digitalMarketing from "../img/digitalMarketing.png";
import facebook from "../img/facebook.png";


const Facebook = () => {
    return (
        <>
        <Helmet>
        <title>Arc Solutions || Facebook</title>
        <meta name="description" content="Learn more about Arc Solutions, a leading IT Solution provider." />
        <meta name="keywords" content="Arc Solutions, IT services, Web Development, ERP Solutions, Software Development" />
        <meta name="author" content="Arc Solutions" />
      </Helmet>
            <div className="bg-orange-500 h-60 flex items-center justify-center">
                <div className="flex flex-col max-w-4xl items-center justify-center">
                    <div className="text-white text-4xl font-bold">Facebook</div><br></br>
                    <span class="text-white">Home/Facebook</span>
                    <div className="text-white">

                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-7">
                        <h3>Facebook Marketing Services</h3>
                        <p>
                            Unlock the power of Facebook marketing with our comprehensive services designed to boost your brand's online presence and engagement. Facebook is one of the most influential social media platforms, with over 2.8 billion monthly active users. Leveraging this platform effectively can help you reach a vast audience, drive traffic to your website, and increase sales.
                        </p>
                        <h4 className="">Our Facebook Marketing Services Include:</h4>
                        <h6 className="mt-3">1.&nbsp; Profile Setup and Optimization:</h6>
                        <li>Creation and optimization of your Facebook business page to ensure it aligns with your brand identity.</li><li>Custom cover photos, profile pictures, and compelling bio descriptions.</li>

                        <h6 className="mt-3">2.&nbsp; Content Creation and Management:</h6>
                        <li>Development of a content strategy tailored to your business goals.</li><li>Regular posting of high-quality content, including images, videos, and articles.</li><li>Engaging posts to attract and retain followers.</li>




                    </div>
                    <div className="col-md-5">
                        <img
                            src={digitalMarketing}
                            className=""
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-7">
                        <h6 className="mt-3">3.&nbsp; Audience Targeting:</h6>
                        <li>Identification of your target audience based on demographics, interests, and behaviors.</li><li>Custom audience creation to reach potential customers who have interacted with your business.</li>

                        <h6 className="mt-3">3.&nbsp; Ad Campaigns:</h6>
                        <li>Design and management of effective Facebook ad campaigns.</li><li>Use of various ad formats, including carousel ads, video ads, and lead generation ads.</li><li>Budget management and ad performance optimization.</li>

                        <h6 className="mt-3">3.&nbsp; Analytics and Reporting:</h6>
                        <li>Regular analysis of your Facebook page performance.</li><li>Detailed reports on engagement metrics, reach, and conversion rates.</li><li>Insights to refine strategies and improve results.</li>

                        <h6 className="mt-3">3.&nbsp; Community Management:</h6>
                        <li>Prompt response to comments, messages, and reviews.</li><li>Building and nurturing a loyal community around your brand.</li>
                    </div>
                    <div className="col-md-5">
                        <img
                            src={facebook}
                            className=""
                        />
                    </div>
                    
                </div>
            </div>




            <Footer />
        </>
    );
};

export default Facebook;
