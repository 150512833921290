import React from "react";
function NotFound()
{
    return(
        <div>
            <h1 style={{"background":"black","color":"red","fontSize":"70px"}}>
                Request Url Is not Found Err : 404
            </h1>
        </div>
    )
}
export default NotFound;