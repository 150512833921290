import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Footer } from "../component/Footer";
import { Helmet } from "react-helmet-async"; // Import Helmet
// Additional images for cards3
import Portfolio1 from "../img/portfolio/1.png";
import Portfolio2 from "../img/portfolio/2.png";
import Portfolio3 from "../img/portfolio/3.png";
import Portfolio4 from "../img/portfolio/4.png";
import Portfolio5 from "../img/portfolio/5.png";
import Portfolio6 from "../img/portfolio/6.png";
import Portfolio7 from "../img/portfolio/7.png";
import Portfolio8 from "../img/portfolio/8.png";
import Portfolio9 from "../img/portfolio/9.png";
import Portfolio10 from "../img/portfolio/10.png";
import Portfolio11 from "../img/portfolio/11.png";
import Portfolio12 from "../img/portfolio/12.png";
import Portfolio13 from "../img/portfolio/13.png";
import Portfolio14 from "../img/portfolio/14.png";
import Portfolio15 from "../img/portfolio/15.png";
import Portfolio16 from "../img/portfolio/16.png";
import Portfolio17 from "../img/portfolio/17.png";
import Portfolio18 from "../img/portfolio/18.png";
import Portfolio19 from "../img/portfolio/19.png";
import Portfolio20 from "../img/portfolio/20.png";
import Portfolio21 from "../img/portfolio/21.png";
const Portfolio = () => {
  const [showAll, setShowAll] = useState(false);

  const cards3 = [
    { image: Portfolio1, title: "Cool Cotton", description: "₹999" },
    { image: Portfolio2, title: "Ethnique", description: "₹999" },
    { image: Portfolio3, title: "Indigo Chronicles", description: "₹999" },
    { image: Portfolio4, title: "Office Oomph", description: "₹999" },
    { image: Portfolio5, title: "Office Oomph", description: "₹999" },
    { image: Portfolio6, title: "Office Oomph", description: "₹999" },
    { image: Portfolio7, title: "Office Oomph", description: "₹999" },
    { image: Portfolio8, title: "Office Oomph", description: "₹999" },
    { image: Portfolio9, title: "Office Oomph", description: "₹999" },
    { image: Portfolio10, title: "Office Oomph", description: "₹999" },
    { image: Portfolio11, title: "Office Oomph", description: "₹999" },
    { image: Portfolio12, title: "Office Oomph", description: "₹999" },
    { image: Portfolio13, title: "Office Oomph", description: "₹999" },
    { image: Portfolio14, title: "Office Oomph", description: "₹999" },
    { image: Portfolio15, title: "Office Oomph", description: "₹999" },
    { image: Portfolio16, title: "Office Oomph", description: "₹999" },
    { image: Portfolio17, title: "Office Oomph", description: "₹999" },
    { image: Portfolio18, title: "Office Oomph", description: "₹999" },
    { image: Portfolio19, title: "Office Oomph", description: "₹999" },
    { image: Portfolio20, title: "Office Oomph", description: "₹999" },
    { image: Portfolio21, title: "Office Oomph", description: "₹999" },
    
  ];

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, left: "-30px", zIndex: "1", display: "block" }}
        onClick={onClick}
      />
    );
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, right: "-30px", zIndex: "1", display: "block" }}
        onClick={onClick}
      />
    );
  };

  const settingsCards3 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  const slideStyles = {
    margin: "0 auto",
    width: "90%",
    position: "relative",
  };

  return (
    <>
    <Helmet>
        <title>Arc Solutions || Portfolio</title>
        <meta name="description" content="Learn more about Arc Solutions, a leading IT Solution provider." />
        <meta name="keywords" content="Arc Solutions, IT services, Web Development, ERP Solutions, Software Development" />
        <meta name="author" content="Arc Solutions" />
      </Helmet>
      <div className="bg-orange-500 h-72 flex items-center justify-center">
        <div className="flex flex-col max-w-4xl items-center justify-center">
          <div className="text-white text-4xl font-bold">OUR PORTFOLIO</div>
          <div className="text-white">
            <p>Home / Portfolio</p>
          </div>
        </div>
      </div>
      {/* Our portfolio section start */}
      <center>
        <div className="carousel-container" style={slideStyles}>
          <div className="flex flex-col text-center w-full mb-6"></div>
          {!showAll ? (
            <Slider {...settingsCards3}>
              {cards3.map((card, index) => (
                <div key={index} className="portfolio-card">
                  <a href="">
                    <div className="portfolio-card-inner">
                      <img
                        src={card.image}
                        alt={card.title}
                        className="portfolio-image"
                      />
                    </div>
                  </a>
                </div>
              ))}
            </Slider>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {cards3.map((card, index) => (
                <div key={index} className="portfolio-card">
                  <a href="">
                    <div className="portfolio-card-inner">
                      <img
                        src={card.image}
                        alt={card.title}
                        className="portfolio-image"
                      />
                    </div>
                  </a>
                </div>
              ))}
            </div>
          )}
          <div className="flex justify-center mt-4">
            {!showAll && (
              <button
                className="bg-blue-900 hover:bg-orange-500 text-white py-2 px-4 rounded-lg focus:outline-none"
                onClick={() => setShowAll(true)}
              >
                View More
              </button>
            )}
          </div>
        </div>
      </center>
      {/* Our portfolio section End */}

      <style jsx>{`
        .portfolio-card {
          margin-bottom: 20px; /* Adjust as needed */
          width: 100%; /* Ensure each card takes full width */
          max-width: 300px; /* Limit card width if necessary */
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for card effect */
          border-radius: 8px; /* Optional: Add rounded corners */
          overflow: hidden; /* Ensure images don't overflow */
        }
        .portfolio-card-inner {
          padding: 10px; /* Optional: Add padding inside the card */
        }
        .portfolio-image {
          width: 100%; /* Ensure images take full width inside the card */
          height: auto; /* Maintain aspect ratio */
          display: block; /* Ensure proper image rendering */
        }
      `}</style>

    

      <Footer />
    </>
  );
};

export default Portfolio;
