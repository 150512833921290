import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Footer } from "../component/Footer";

// Additional images for cards3
import service5 from '../img/API.png';
import service from '../img/1app.png';
import service1 from '../img/1wevdevelpoment.png';
import service2 from '../img/ecomm.png';
import service3 from '../img/1software.png';
import service4 from '../img/1digital.png';


const DevelopmentServices = () => {
    return (
        <>
            <div className="bg-orange-500 h-60 flex items-center justify-center">
                <div className="flex flex-col max-w-4xl items-center justify-center">
                    <div className="text-white text-4xl font-bold">Our Development Services</div><br></br>
                    <a href="/" class="text-white text-decoration-none  hover:decoration-4">Home/DevelopmentServices</a>
                    <div className="text-white">

                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-7 col-sm-12 col-xs-12">
                        <h3>App Development</h3>
                        <p>Arc Solutions's professional Android app developers have both the expertise and experience to develop innovative, interactive, feature-packed and high-functioning Android-based applications ready for prime time on Google Play.</p>
                        <p>At our company, we specialize in creating robust, user-friendly, and innovative applications tailored to meet your unique business needs. Our team of skilled developers leverages the latest technologies and industry best practices to build custom apps that enhance user engagement, streamline operations, and drive growth. Whether you're looking to develop a mobile app, web app, or a complex enterprise solution, we ensure a seamless development process from concept to deployment. With a strong focus on quality, security, and performance, we deliver solutions that not only meet but exceed your expectations, helping you stay ahead in today's competitive digital landscape.</p>
                    </div>
                    <div className="col-md-5 col-sm-12 col-xs-12">
                    <center><img className="d-block w-100" src={service} alt={service} /></center>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-md-7 col-sm-12 col-xs-12">
                        <h3>Web Development</h3>
                        <p>At Arc Solutions, our front-end as well as the back-end website programmers are highly trained coding experts. Our development team is always acquiring new tricks to stay on the top of modern programming practices and concepts. Whether its PHP, Ajax or .Net, our development team has the skills to develop any website.</p>
                        <p>Our  company excels in crafting bespoke websites that are visually appealing, highly functional, and perfectly aligned with your business objectives. We offer end-to-end web development services, from initial strategy and design to development, deployment, and ongoing maintenance. Our expert team utilizes cutting-edge technologies and follows the latest industry trends to ensure your website is fast, secure, and scalable. Whether you need a simple brochure site, an e-commerce platform, or a complex web application, we tailor our solutions to deliver exceptional user experiences and measurable business results. Partner with us to transform your online presence and drive your business forward.</p>
                    </div>
                    <div className="col-md-5 col-sm-12 col-xs-12">
                    <center><img className="d-block w-100" src={service1} alt={service1} /></center>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-md-7 col-sm-12 col-xs-12">
                        <h3>e-Commerce Development</h3>
                        <p>In the fast-paced world of online shopping, our e-commerce development services stand out by creating secure, scalable, and user-friendly platforms that drive sales and enhance customer satisfaction. Our team of experienced developers and designers work collaboratively to build e-commerce websites tailored to your specific needs, whether you're a small startup or an established enterprise. We integrate powerful features such as payment gateways, inventory management, and customer relationship tools to ensure a seamless shopping experience. From stunning storefronts to comprehensive backend solutions, we help you establish a strong online presence, increase conversions, and grow your business in the competitive digital marketplace. Partner with us to unlock the full potential of e-commerce for your brand.</p>
                    </div>
                    <div className="col-md-5 col-sm-12 col-xs-12">
                    <center><img className="d-block w-100" src={service2} alt={service2} /></center>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-md-7 col-sm-12 col-xs-12">
                        <h3>Software Development</h3>
                        <p>The customized services offered by Arc Solutions will help you build apps that are a good fit for your existing infrastructure and gel well with your environment.We provide high quality software that meets your needs, whether you require intermediary blocks built into your system or require an end-to-end solution.</p><p>Our software development services are designed to bring your vision to life with precision and innovation. We specialize in creating custom software solutions that address your unique business challenges and streamline your operations. Our team of skilled developers and engineers employs the latest technologies and agile methodologies to deliver high-quality software that is scalable, secure, and user-friendly. Whether you need enterprise software, SaaS solutions, or custom applications, we provide end-to-end development services from ideation and prototyping to deployment and support. By partnering with us, you gain access to a wealth of expertise and a commitment to excellence that ensures your software not only meets but exceeds your expectations, empowering your business to thrive in a digital world.</p>
                    </div>
                    <div className="col-md-5 col-sm-12 col-xs-12">
                    <center><img className="d-block w-100" src={service3} alt={service3} /></center>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-md-7 col-sm-12 col-xs-12">
                        <h3>Digital Marketing</h3>
                        <p>Our primary aim is delivering the best insights and strategies for our customers to enhance the sales and outperform their competitors. Our digital marketing services are strategic, innovative and market leading that brings commercial success for our valuable customers.</p><p>Our digital marketing services are crafted to boost your online presence, drive traffic, and convert visitors into loyal customers. We employ a comprehensive approach that includes SEO, social media marketing, PPC advertising, content marketing, and email campaigns to reach your target audience effectively. Our team of digital marketing experts stays ahead of the latest trends and uses data-driven strategies to deliver measurable results. Whether you're looking to increase brand awareness, generate leads, or improve your search engine rankings, we tailor our solutions to meet your specific goals. Partner with us to leverage the power of digital marketing and achieve sustained growth in today's competitive online landscape.</p>
                    </div>
                    <div className="col-md-5 col-sm-12 col-xs-12">
                    <center><img className="d-block w-100" src={service4} alt={service4} /></center>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-md-7 col-sm-12 col-xs-12">
                        <h3>API Development</h3>
                        <p>Our API development services empower your business to seamlessly integrate and communicate between different software systems, enhancing functionality and efficiency. We specialize in creating robust, secure, and scalable APIs tailored to your specific needs. Our expert developers use best practices and cutting-edge technologies to design APIs that facilitate smooth data exchange, streamline processes, and enable third-party integrations. Whether you need APIs for mobile applications, web services, or enterprise systems, we ensure they are well-documented, easy to use, and capable of handling high traffic loads. Partner with us to build APIs that boost your operational capabilities and drive innovation in your digital ecosystem.</p>
                    </div>
                    <div className="col-md-5 col-sm-12 col-xs-12">
                    <center><img className="d-block w-100" src={service5} alt={service5} /></center>
                    </div>
                </div>
                
            </div>
            <Footer />
        </>
    );
};

export default DevelopmentServices;
