import React, { useState } from "react";
import Slider from "react-slick";
import { Helmet } from "react-helmet-async"; // Import Helmet
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Footer } from "./Footer";

// Additional images for cards3
import digitalMarketing from "../img/digitalMarketing.png";
import facebook from "../img/facebook.png";


const Instagram = () => {
    return (
        <>
        <Helmet>
        <title>Arc solutions || Instagram</title>
        <meta name="description" content="Learn more about Arc Solutions, a leading IT Solution provider." />
        <meta name="keywords" content="Arc Solutions, IT services, Web Development, ERP Solutions, Software Development" />
        <meta name="author" content="Arc Solutions" />
      </Helmet>
            <div className="bg-orange-500 h-60 flex items-center justify-center">
                <div className="flex flex-col max-w-4xl items-center justify-center">
                    <div className="text-white text-4xl font-bold">Instagram</div><br></br>
                    <span class="text-white">Home/Instagram</span>
                    <div className="text-white">

                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-7">
                        <h3>Instagram Marketing Services</h3>
                        <p>
                        Elevate your brand's visibility and engagement on Instagram with our specialized marketing services. Instagram is a visually-driven platform with over 1 billion active users, making it a powerful tool for businesses to showcase their products, connect with their audience, and drive sales.
                        </p>
                        <h4 className="">Our Instagram Marketing Services Include:</h4>
                        <h6 className="mt-3">1.&nbsp; Profile Setup and Optimization:</h6>
                        <li>Creation and optimization of your Instagram business profile to ensure it reflects your brand identity.</li><li>Custom profile picture, bio, and story highlights.</li>

                        <h6 className="mt-3">2.&nbsp; Content Creation and Curation:</h6>
                        <li>Development of a content strategy tailored to your business goals.</li><li>Regular posting of high-quality content, including photos, videos, carousels, and stories.</li><li>Use of visually appealing graphics and engaging captions.
                        </li>




                    </div>
                    <div className="col-md-5">
                        <img
                            src={digitalMarketing}
                            className=""
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-7">
                        <h6 className="mt-3">3.&nbsp; Audience Engagement:</h6>
                        <li>Identification and targeting of your ideal audience based on demographics, interests, and behaviors.</li><li>Strategies to increase followers, likes, comments, and shares.</li>

                        <h6 className="mt-3">4.&nbsp; Influencer Partnerships:</h6>
                        <li>Identification and collaboration with relevant influencers to expand your reach.</li><li>Management of influencer campaigns to ensure alignment with your brand values and objectives.</li>

                        <h6 className="mt-3">5.&nbsp;Ad Campaigns:</h6>
                        <li>Design and management of effective Instagram ad campaigns.</li><li>Utilization of various ad formats, including photo ads, video ads, story ads, and carousel ads.</li><li>Budget management and ad performance optimization.</li>

                        <h6 className="mt-3">6.&nbsp; Analytics and Reporting:</h6>
                        <li>Regular analysis of your Instagram profile performance.</li><li>Detailed reports on engagement metrics, reach, follower growth, and conversion rates.</li>
                        <li>Insights to refine strategies and improve results.</li>

                        <h6 className="mt-3">7.&nbsp; Community Management:</h6>
                        <li>Prompt response to comments, direct messages, and mentions.</li>
                        <li>Building and nurturing a loyal community around your brand.</li>

                    </div>
                    <div className="col-md-5">
                        <img
                            src={facebook}
                            className=""
                        />
                    </div>
                    
                </div>
            </div>




            <Footer />
        </>
    );
};

export default Instagram;
