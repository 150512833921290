import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Footer } from "../component/Footer";
import { Helmet } from "react-helmet-async"; // Import Helmet
// Additional images for cards3
import digitalMarketing from "../img/digitalMarketing.png";
import LinkedIn1 from "../img/linkedin.png";
import LinkedIn2 from "../img/linkedin1.png";


const LinkedIn = () => {
    return (
        <>
         <Helmet>
        <title>Arc Solutions || Linkdin </title>
        <meta name="description" content="Learn more about Arc Solutions, a leading IT Solution provider." />
        <meta name="keywords" content="Arc Solutions, IT services, Web Development, ERP Solutions, Software Development" />
        <meta name="author" content="Arc Solutions" />
      </Helmet>
            <div className="bg-orange-500 h-60 flex items-center justify-center">
                <div className="flex flex-col max-w-4xl items-center justify-center">
                    <div className="text-white text-4xl font-bold">LinkedIn</div><br></br>
                    <a href="/" class="text-white text-decoration-none  hover:decoration-4">Home/LinkedIn</a>
                    <div className="text-white">

                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-7">
                        <h3>LinkedIn Marketing Services</h3>
                        <p>
                        Elevate your brand's online presence and engagement with our specialized LinkedIn marketing services. With over 2.8 billion monthly active users, LinkedIn stands as a powerhouse for professional networking and business growth. Our tailored services are designed to leverage this platform effectively, helping you connect with a vast audience, drive website traffic, and boost sales.
                        </p>
                        <h4 className="">Our LinkedIn Marketing Services Include:</h4>
                        <h6 className="mt-3">1.&nbsp; Profile Setup and Optimization:</h6>
                        <li>Create and optimize your LinkedIn business page to reflect your brand identity.</li>
                        <li>Design custom cover photos, profile pictures, and craft compelling bio descriptions.</li>

                        <h6 className="mt-3">2.&nbsp; Content Creation and Management:</h6>
                        <li>Develop a targeted content strategy aligned with your business objectives.</li>
                        <li>Consistently publish high-quality content, including images, videos, and articles.</li>
                        <li>Engage your audience with relevant and valuable posts.</li>




                    </div>
                    <div className="col-md-5">
                        <img
                            src={LinkedIn2}
                            className=""
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-7">
                        <h6 className="mt-3">3.&nbsp; Audience Targeting:</h6>
                        <li>Identify and reach your ideal audience based on demographics, interests, and behavior.</li>
                        <li>Utilize custom audience creation to engage potential customers who have shown interest in your offerings.</li>

                        <h6 className="mt-3">3.&nbsp; Ad Campaigns:</h6>
                        <li>Strategize and execute effective LinkedIn ad campaigns..</li>
                        <li>Utilize diverse ad formats such as carousel ads, video ads, and lead generation ads.</li>
                        <li>Manage budgets and optimize ad performance for maximum ROI.</li>



                        <h6 className="mt-3">3.&nbsp; Analytics and Reporting:</h6>
                        <li>Monitor and analyze LinkedIn page performance metrics regularly..</li>
                        <li>Provide detailed reports on engagement, reach, and conversion rates..</li><li>Use insights to refine strategies and achieve better results.</li>

                        <h6 className="mt-3">3.&nbsp; Community Management:</h6>
                        <li>Foster a responsive and engaged community by promptly addressing comments, messages, and reviews..</li>
                        <li>Cultivate a loyal following and strengthen brand loyalty through meaningful interactions.</li>
                    </div>
                    <div className="col-md-5">
                        <img
                            src={LinkedIn1}
                            className=""
                        />
                    </div>
                    
                </div>
            </div>




            <Footer />
        </>
    );
};

export default LinkedIn;
