import React from "react";
import { Footer } from "../component/Footer";
import "./Android_developer.css";
import android from "../img/android.png";
import team from "../img/teamwork.png";
import team2 from "../img/trust.png";
import team3 from "../img/group.png";
import team4 from "../img/man.png";
import team5 from "../img/circular-economy.png";
import team6 from "../img/pencil.png";
import { Helmet } from "react-helmet-async"; // Import Helmet
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGraduationCap,
  faHeartbeat,
  faDumbbell,
  faShoppingCart,
  faLaptopCode,
  faShoppingBag,
  faBasketballBall,
  faBriefcase,
} from "@fortawesome/free-solid-svg-icons";

function Ios_developer() {
  return (
    <div>
      <Helmet>
        <title>Arc Solutions || IOS Developer</title>
        <meta name="description" content="Learn more about Arc Solutions, a leading IT Solution provider." />
        <meta name="keywords" content="Arc Solutions, IT services, Web Development, ERP Solutions, Software Development" />
        <meta name="author" content="Arc Solutions" />
      </Helmet>
      <div className="main-home container-fluid">
        <div className="row">
          <div className="col-md-6 col-sm-12 col-xs-12">
            <section className="main-home">
              <div className="main-text">
                <h1>
                  Custom iOS App <br /> Development <br />
                  Company
                </h1>
                <p>
                  Develop next-gen iOS apps that are quality tested for
                  perfection with future-forward iOS app development services.
                  We hold expertise in developing the best iOS application
                  solution, which helps our clients to meet the ever-evolving
                  demands of their end-users. Every established business and
                  startup choose us as their technology partner and gets expert
                  advice.
                </p>
                <a href="#" class="main-btn1 font-bold">
                  FREE CONSULTATION
                </a>
              </div>
            </section>
          </div>
          <div className="col-md-6">
            <section className="main-home">
              <img
                className="d-block w-100"
                src={android}
                alt="Banner"
                style={{ height: "100%", objectFit: "cover" }}
              />
            </section>
          </div>
        </div>
      </div>

      <section
        className="py-10 from-fuchsia-600 to-blue-600 sm:py-16 lg:py-24"
        style={{ backgroundColor: "#e6efff" }}
      >
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="mx-auto text-left sm:text-center">
            <h2 className="text-3xl font-bold leading-tight text-dark sm:text-4xl lg:text-5xl lg:leading-tight">
              Our iOS App Development Services
            </h2>
            <p className="mt-4 text-xl text-dark">
              Arc Solutions is your iOS mobile application development company
              that builds stunning user-centred mobile applications for startups
              to enterprise businesses using an innovative development approach.
              The interfaces and software we construct depend heavily on your
              business objectives, products, and unique needs to best reach your
              target audience. Whether you're keen on building a mobile app for
              the iPhone, iPad, Apple TV or any other iOS device, it is no doubt
              that the iOS app market worldwide is booming with Apple's strong
              mobile app market share. We have a team of experienced iOS app
              developers delivering high-performing products for iPhone iOS
              mobile applications which run on both iPad and iPhone, including
              the latest iPhone and iPad devices. Keeping track of the latest
              mobile app development technologies, our expert mobile app
              developers provide solutions that meet clients' diverse and
              complex needs. We help you build new products adjacent to the core
              business values to win exponential market growth.
            </p>
          </div>

          <div
            className="bg-white py-6 sm:py-8 lg:py-12"
            style={{ paddingLeft: "5rem", paddingRight: "5rem" }}
          >
            <div className="mx-auto max-w-screen-xl px-4 md:px-8">
              <div className="grid gap-4 md:grid-cols-2 md:gap-8">
                <div className="flex flex-col items-center gap-4 rounded-lg bg-indigo-500 px-8 py-6 md:gap-6 hover:bg-indigo-600 transition duration-300 transform hover:scale-105">
                  <div className="max-w-md text-center text-white lg:text-lg">
                    <h3>Full-Stack iOS App Development Services</h3>
                    <p className="text-justify">
                      Our domain expertise of more than six years enables us to
                      create iPhone applications that are secure, scalable,
                      feature-rich, and high-performing
                    </p>
                  </div>
                </div>

                <div className="flex flex-col items-center gap-4 rounded-lg bg-indigo-500 px-8 py-6 md:gap-6 hover:bg-indigo-600 transition duration-300 transform hover:scale-105">
                  <div className="max-w-md text-center text-white lg:text-lg">
                    <h3>Enterprise-Oriented Solutions (EOS)</h3>
                    <p className="text-justify">
                      Get enterprise-oriented iOS/ iPhone mobile applications
                      that are high-performing, secure, scalable, and
                      feature-rich, tailored to your company's requirements.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="mx-auto max-w-screen-xl px-4 md:px-8">
              <div className="grid gap-4 md:grid-cols-2 md:gap-8">
                <div className="flex flex-col items-center gap-4 rounded-lg bg-indigo-500 px-8 py-6 md:gap-6 hover:bg-indigo-600 transition duration-300 transform hover:scale-105">
                  <div className="max-w-md text-center text-white lg:text-lg">
                    <h3>Migration and Upgrades</h3>
                    <p className="text-justify">
                      Our iPhone app developers are always ready to assist you
                      in migrating your app to the iOS platform or upgrading to
                      the latest version of an iOS / iPhone app.
                    </p>
                  </div>
                </div>

                <div className="flex flex-col items-center gap-4 rounded-lg bg-indigo-500 px-8 py-6 md:gap-6 hover:bg-indigo-600 transition duration-300 transform hover:scale-105">
                  <div className="max-w-md text-center text-white lg:text-lg">
                    <h3>Consulting Services for iOS Mobile App Development.</h3>
                    <p className="text-justify">
                      You can contact our professionals if you have any iOS /
                      iPhone mobile app development questions. Our iOS mobile
                      app development team is standing by to assist you.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="mx-auto max-w-screen-xl px-4 md:px-8">
              <div className="grid gap-4 md:grid-cols-2 md:gap-8">
                <div className="flex flex-col items-center gap-4 rounded-lg bg-indigo-500 px-8 py-6 md:gap-6 hover:bg-indigo-600 transition duration-300 transform hover:scale-105">
                  <div className="max-w-md text-center text-white lg:text-lg">
                    <h3>Support & maintenance for iOS Apps</h3>
                    <p className="text-justify">
                      We can provide top-notch iOS app development support and
                      maintenance to clients and provide iPhone application
                      creation services.
                    </p>
                  </div>
                </div>

                <div className="flex flex-col items-center gap-4 rounded-lg bg-indigo-500 px-8 py-6 md:gap-6 hover:bg-indigo-600 transition duration-300 transform hover:scale-105">
                  <div className="max-w-md text-center text-white lg:text-lg">
                    <h3>API Integration and Development</h3>
                    <p className="text-justify">
                      We involve our professional quality analysts in the
                      project from the beginning to ensure that your app works
                      across all browsers and screens.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="mx-auto max-w-screen-xl px-4 md:px-8">
              <div className="grid gap-4 md:grid-cols-2 md:gap-8">
                <div className="flex flex-col items-center gap-4 rounded-lg bg-indigo-500 px-8 py-6 md:gap-6 hover:bg-indigo-600 transition duration-300 transform hover:scale-105">
                  <div className="max-w-md text-center text-white lg:text-lg">
                    <h3>Testing and Quality Assurance Services</h3>
                    <p className="text-justify">
                      We offers a team of experts dedicated to testing all of
                      your Android apps for bugs thoroughly. We ensure that each
                      app is bug-free and performs at a high level for your
                      clients.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center1 md:font-bold">
              <b>
                <a
                  href="#"
                  class="btn custom-btn common-btn blue text-white"
                  style={{ marginTop: "-12px" }}
                >
                  GET A FREE CONSULTATION
                </a>
              </b>
            </div>
          </div>
        </div>
      </section>

      <div className="container mx-auto px-4 md:px-8">
        <div className="row">
          <div className="col-md-12">
            <div class="bg-white py-6 sm:py-8 lg:py-12">
              <div class="mx-auto max-w-screen-xl px-4 md:px-8">
                {/* <!-- text - start --> */}
                <div class="mb-10 md:mb-16">
                  <p class="mb-4 text-center font-bold text-blue-800 md:mb-6 sm:text-2xl">
                    Reasons our Clients Prefer Us as
                  </p>
                  <h1 class="text-center  font-bold">
                    IOS App Development Company
                  </h1>

                  <p className="mt-3 text-xl text-dark">
                    We are a top-rated iOS application development company
                    recognized for meeting modern business needs and delivering
                    best-in-class solutions at a reasonable price worldwide.
                    Here are some reasons we are the ideal choice for developing
                    iPhone apps. Our iOS mobile app development team is
                    committed to incorporating all emerging technologies into
                    your app because we have many clients. We've been providing
                    high-quality iOS mobile application development services to
                    clients worldwide. Our happy customers can be found
                    throughout the USA, Europe, Australia, Germany, UK, and
                    India because our iOS apps help businesses grow. Our main
                    objective is to keep you happy and make your customers'
                    mobile experience as simple as possible.
                  </p>
                </div>
                {/* <!-- text - end --> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Card section Start*/}
      <section class="py-10 bg-gray-100 sm:py-16 lg:py-24">
        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div class="max-w-2xl mx-auto text-center"></div>

          <div class="grid grid-cols-1 mx-auto mt-8 text-center lg:grid-cols-3 gap-x-8 gap-y-8 sm:mt-12 lg:mt-20 max-w-full">
            <div class="overflow-hidden rounded-lg shadow-lg hover-card">
              <div class="px-12 py-16">
                <div class="relative w-28 h-28 mx-auto img-container">
                  <img
                    class="relative object-cover w-28 h-28 mx-auto rounded-full img-zoom"
                    src={team}
                    alt=""
                  />
                  <div class="absolute top-0 right-0 flex items-center justify-center bg-blue-600 rounded-full w-8 h-8">
                    <svg
                      class="w-5 h-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M20.309 17.708C22.196 15.66 22.006 13.03 22 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292zm-11.007 0C11.19 15.66 10.999 13.03 10.993 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292z"></path>
                    </svg>
                  </div>
                </div>
                <blockquote class="mt-8">
                  <h2 class="text-2xl font-semibold text-gray-900 hover-h2">
                    IOS App Expertise
                  </h2>
                  <p class="mt-4 text-lg text-gray-700 hover-p">
                    Arc Solutions has invested immensely in IOS App team
                    expansion and has a well-established team of IOS App
                    experts.
                  </p>
                </blockquote>
              </div>
            </div>
            <div class="overflow-hidden rounded-lg shadow-lg hover-card">
              <div class="px-12 py-16">
                <div class="relative w-28 h-28 mx-auto img-container">
                  <img
                    class="relative object-cover w-28 h-28 mx-auto rounded-full img-zoom"
                    src={team5}
                    alt=""
                  />
                  <div class="absolute top-0 right-0 flex items-center justify-center bg-blue-600 rounded-full w-8 h-8">
                    <svg
                      class="w-5 h-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M20.309 17.708C22.196 15.66 22.006 13.03 22 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292zm-11.007 0C11.19 15.66 10.999 13.03 10.993 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292z"></path>
                    </svg>
                  </div>
                </div>
                <blockquote class="mt-8">
                  <h2 class="text-2xl font-semibold text-gray-900 hover-h2">
                    Cost Effective Partners
                  </h2>
                  <p class="mt-4 text-lg text-gray-700 hover-p">
                    Arc Solutions provides cost-effective solutions, which makes
                    us the partner of your choice.
                  </p>
                </blockquote>
              </div>
            </div>
            <div class="overflow-hidden rounded-lg shadow-lg hover-card">
              <div class="px-12 py-16">
                <div class="relative w-28 h-28 mx-auto img-container">
                  <img
                    class="relative object-cover w-28 h-28 mx-auto rounded-full img-zoom"
                    src={team6}
                    alt=""
                  />
                  <div class="absolute top-0 right-0 flex items-center justify-center bg-blue-600 rounded-full w-8 h-8">
                    <svg
                      class="w-5 h-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M20.309 17.708C22.196 15.66 22.006 13.03 22 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292zm-11.007 0C11.19 15.66 10.999 13.03 10.993 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292z"></path>
                    </svg>
                  </div>
                </div>
                <blockquote class="mt-8">
                  <h2 class="text-2xl font-semibold text-gray-900 hover-h2">
                    Agile Development & On-Time Delivery
                  </h2>
                  <p class="mt-4 text-lg text-gray-700 hover-p">
                    Our modern Agile approach to project management allows us to
                    deliver on time with quality.
                  </p>
                </blockquote>
              </div>
            </div>
            <div class="overflow-hidden rounded-lg shadow-lg hover-card">
              <div class="px-12 py-16">
                <div class="relative w-28 h-28 mx-auto img-container">
                  <img
                    class="relative object-cover w-28 h-28 mx-auto rounded-full img-zoom"
                    src={team2}
                    alt=""
                  />
                  <div class="absolute top-0 right-0 flex items-center justify-center bg-blue-600 rounded-full w-8 h-8">
                    <svg
                      class="w-5 h-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M20.309 17.708C22.196 15.66 22.006 13.03 22 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292zm-11.007 0C11.19 15.66 10.999 13.03 10.993 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292z"></path>
                    </svg>
                  </div>
                </div>
                <blockquote class="mt-8">
                  <h2 class="text-2xl font-semibold text-gray-900 hover-h2">
                    Easy and Regular Communication
                  </h2>
                  <p class="mt-4 text-lg text-gray-700 hover-p">
                    We use the communication channel that our client prefers,
                    making it easy for our clients to communicate.
                  </p>
                </blockquote>
              </div>
            </div>
            <div class="overflow-hidden rounded-lg shadow-lg hover-card">
              <div class="px-12 py-16">
                <div class="relative w-28 h-28 mx-auto img-container">
                  <img
                    class="relative object-cover w-28 h-28 mx-auto rounded-full img-zoom"
                    src={team3}
                    alt=""
                  />
                  <div class="absolute top-0 right-0 flex items-center justify-center bg-blue-600 rounded-full w-8 h-8">
                    <svg
                      class="w-5 h-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M20.309 17.708C22.196 15.66 22.006 13.03 22 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292zm-11.007 0C11.19 15.66 10.999 13.03 10.993 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292z"></path>
                    </svg>
                  </div>
                </div>
                <blockquote class="mt-8">
                  <h2 class="text-2xl font-semibold text-gray-900 hover-h2">
                    Free Post development Support
                  </h2>
                  <p class="mt-4 text-lg text-gray-700 hover-p">
                    Our clients can focus on marketing as we hold their back by
                    providing free and best post-development support.
                  </p>
                </blockquote>
              </div>
            </div>
            <div class="overflow-hidden rounded-lg shadow-lg hover-card">
              <div class="px-12 py-16">
                <div class="relative w-28 h-28 mx-auto img-container">
                  <img
                    class="relative object-cover w-28 h-28 mx-auto rounded-full img-zoom"
                    src={team4}
                    alt=""
                  />
                  <div class="absolute top-0 right-0 flex items-center justify-center bg-blue-600 rounded-full w-8 h-8">
                    <svg
                      class="w-5 h-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M20.309 17.708C22.196 15.66 22.006 13.03 22 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292zm-11.007 0C11.19 15.66 10.999 13.03 10.993 13V5a1 1 0 0 0-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 0 0 1 1h3.078a2.89 2.89 0 0 1-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292z"></path>
                    </svg>
                  </div>
                </div>
                <blockquote class="mt-8">
                  <h2 class="text-2xl font-semibold text-gray-900 hover-h2">
                    Starts at $18/H
                  </h2>
                  <p class="mt-4 text-lg text-gray-700 hover-p">
                    Our hourly rates for dedicated resources start at $18 /
                    Hour, which are the most competitive in the market.
                  </p>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Card section End*/}

      <section
        className="py-10 from-fuchsia-600 to-blue-600 sm:py-16 lg:py-24"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="mx-auto text-left sm:text-center">
            <p class="text-center font-bold text-blue-800 md:mb-6 sm:text-2xl">
              Our Process
            </p>
            <h2 className="text-3xl font-bold leading-tight text-dark sm:text-4xl lg:text-5xl lg:leading-tight">
              See what our projects have to go through..!
            </h2>
            <p className="mt-4 text-xl text-dark">
              We are an iOS app development company known for helping businesses
              elevate their business method, create viable solutions, design
              memorable experiences, and code apps that add high value to users'
              lives.{" "}
              <span class="font-bold text-blue-800">
                We've released many native iOS apps
              </span>{" "}
              with tremendous success rates and app store chartbusters. Our core
              stack for developing iOS applications in objective-C and swift has
              a highly skilled staff of seasoned iPhone mobile app developers.
              We do all this by following the below process.
            </p>
          </div>
        </div>
      </section>

      <div className="row col-12">
        <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div class="mx-auto text-left sm:text-center">
            <img
              src="https://www.groovyweb.co/assets/img/assets-img/Diagram.svg"
              height="427"
              width="1376"
              alt="Diagram"
            />
          </div>
          <br />
          <center>
            <a href="#" class="main-btn2 font-bold">
              ESTIMATE MY APP
            </a>{" "}
            &ensp;&ensp;
            <a href="#" class="main-btn1 font-bold">
              KNOW MORE
            </a>
          </center>
        </div>
      </div>

      <section
        className="py-10 from-fuchsia-600 to-blue-600 sm:py-16 lg:py-24"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="mx-auto text-left sm:text-center">
            <h2 className="text-3xl font-bold leading-tight text-dark sm:text-4xl lg:text-5xl lg:leading-tight">
              Industries We Have Worked With
            </h2>
            <p className="mt-4 text-xl text-dark">
              As an{" "}
              <span className="font-bold text-blue-800">
                award-winning mobile app development company,
              </span>{" "}
              We handcraft full-stack app development solutions to clients
              worldwide. We deliver only the best mobile apps from various
              sectors like eCommerce, IT, Sports, Healthcare, Fitness,
              Education, etc. We have a creative design team to make your mobile
              app look more appealing to your end-users. We use advanced
              technologies and follow modern trends to build a great mobile app
              that meets your expectations. Groovy web is recognized as a
              top-rated app development company and a top{" "}
              <span className="font-bold text-blue-800">
                custom software development company
              </span>{" "}
              on reputed platforms like Clutch, GoodFirms, DesignRush, and
              Business of Apps.
            </p>
          </div>
        </div>
        <div className=" py-6 sm:py-8 lg:py-12">
          <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 sm:gap-6 xl:gap-8">
              <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-white shadow-lg md:h-50">
                <div className="absolute inset-0 flex items-center justify-center bg-blue-600">
                  <FontAwesomeIcon
                    icon={faGraduationCap}
                    size="2x"
                    className="text-white"
                  />
                </div>
                <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">
                  Education
                </span>
              </div>
              <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-white shadow-lg md:h-50">
                <div className="absolute inset-0 flex items-center justify-center bg-blue-600">
                  <FontAwesomeIcon
                    icon={faHeartbeat}
                    size="2x"
                    className="text-white"
                  />
                </div>
                <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">
                  Healthcare
                </span>
              </div>
              <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-white shadow-lg md:h-50">
                <div className="absolute inset-0 flex items-center justify-center bg-blue-600">
                  <FontAwesomeIcon
                    icon={faDumbbell}
                    size="2x"
                    className="text-white"
                  />
                </div>
                <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">
                  Fitness
                </span>
              </div>
              <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-white shadow-lg md:h-50">
                <div className="absolute inset-0 flex items-center justify-center bg-blue-600">
                  <FontAwesomeIcon
                    icon={faShoppingCart}
                    size="2x"
                    className="text-white"
                  />
                </div>
                <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">
                  Shopping
                </span>
              </div>
              <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-white shadow-lg md:h-50">
                <div className="absolute inset-0 flex items-center justify-center bg-orange-600">
                  <FontAwesomeIcon
                    icon={faLaptopCode}
                    size="2x"
                    className="text-white"
                  />
                </div>
                <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">
                  IT
                </span>
              </div>
              <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-white shadow-lg md:h-50">
                <div className="absolute inset-0 flex items-center justify-center bg-orange-600">
                  <FontAwesomeIcon
                    icon={faShoppingBag}
                    size="2x"
                    className="text-white"
                  />
                </div>
                <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">
                  eCommerce
                </span>
              </div>
              <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-white shadow-lg md:h-50">
                <div className="absolute inset-0 flex items-center justify-center bg-orange-600">
                  <FontAwesomeIcon
                    icon={faBasketballBall}
                    size="2x"
                    className="text-white"
                  />
                </div>
                <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">
                  Sports
                </span>
              </div>
              <div className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-white shadow-lg md:h-50">
                <div className="absolute inset-0 flex items-center justify-center bg-orange-600">
                  <FontAwesomeIcon
                    icon={faBriefcase}
                    size="2x"
                    className="text-white"
                  />
                </div>
                <div className="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>
                <span className="relative ml-4 mb-3 inline-block text-sm text-white md:ml-5 md:text-lg">
                  Jobs
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="bg-white py-6 sm:py-8 lg:py-12">
        <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
          <div class="mb-10 md:mb-16">
            <h2 class="mb-4 text-center text-2xl font-bold text-blue-800 md:mb-6 lg:text-3xl">
              Frequently asked questions
            </h2>

            <p class="mx-auto max-w-screen-md text-center text-gray-800 md:text-lg">
              We hope these clear your doubts, but if you still have any
              questions, then feel free to write us on www.arcsolutions.org
            </p>
          </div>

          <div class="grid gap-8 sm:grid-cols-2 md:gap-12 xl:grid-cols-4 xl:gap-16">
            <div>
              <h3 class="mb-2 text-lg font-semibold text-blue-800 md:text-xl">
                Do I possess the rights to my app's code and ownership?{" "}
              </h3>
              <p class="text-gray-800">
                We are a renowned iOS app development company. Once your ios app
                has been successfully launched on the Program Store, we will
                pass over the source code and copyright of the app to you. We
                freely discuss source code rights and project transfer with our
                clients before signing any contract.
              </p>
            </div>

            <div>
              <h3 class="mb-2 text-lg font-semibold text-blue-800 md:text-xl">
                What does it cost to create an iPhone application?{" "}
              </h3>
              <p class="text-gray-800">
                The cost of creating an iOS app is determined by a number of
                factors, including the development platform, app categories, app
                complexity, developer country, and the number of features you
                require in your app. If you would like an estimate for
                developing an app, please email us your requirements.
              </p>
            </div>

            <div>
              <h3 class="mb-2 text-lg font-semibold text-blue-800  md:text-xl">
                What things should you think about while choosing an iOS app
                development company?{" "}
              </h3>
              <p class="text-gray-800">
                There are numerous aspects to examine when selecting an iOS
                development business, including development technique,
                historical projects, past client reviews, employee strength,
                etc.
              </p>
            </div>

            <div>
              <h3 class="mb-2 text-lg font-semibold text-blue-800 md:text-xl">
                Is the iOS app you're working on compatible with the most recent
                iPhone version?{" "}
              </h3>
              <p class="text-gray-800">
                Yes, we are working on an app that will run on the most recent
                version of iOS. Our iPhone app development team is current on
                the most recent iOS trends and changes. Turn on screen reader
                support
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Ios_developer;
