import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Helmet } from 'react-helmet-async';
import 'odometer/themes/odometer-theme-default.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin, faTwitter, faWhatsapp, faYoutube } from '@fortawesome/free-brands-svg-icons';

import { Carousel } from 'react-bootstrap';
import { Footer } from '../component/Footer';
// Importing images
import banner1 from '../img/wb_dev_banner.jpg';
import banner2 from '../img/banner_ten.png';


import about from '../img/about-Software.jpg';
import whatsapp from '../img/whatsapp.png';
import phone from '../img/phone.png';
// import Platform images start
import card_1 from '../img/card_four.webp';
import card_2 from '../img/card_one.webp';
import card_3 from '../img/card_three.webp';
import card_4 from '../img/card_five.webp';
import card_5 from '../img/shophy.webp';
import card_6 from '../img/opencart.webp';
import card_7 from '../img/Python-Logo.png';
import card_8 from '../img/html.png';
import card_9 from '../img/Mysql_logo.png';
import card_10 from '../img/ASP.png';
import card_11 from '../img/laravell.png';
import card_12 from '../img/SPRING.png';




// import platform images end






// Additional images for cards2
import image11 from '../img/clients/1.jpg';
import image21 from '../img/clients/2.jpg';
import image31 from '../img/clients/3.jpg';
import image41 from '../img/clients/4.jpg';
import image51 from '../img/clients/5.jpg';
import image61 from '../img/clients/6.jpg';
import image71 from '../img/clients/7.jpg';


// Additional images for cards3
import image111 from '../img/clients/1.png';

import image311 from '../img/clients/photo3.png';
import image411 from '../img/clients/photo4.png';

import image611 from '../img/clients/photo6.png';


// images for services
import service from '../img/1app.png';
import service1 from '../img/1wevdevelpoment.png';
import service2 from '../img/ecomm.png';
import service3 from '../img/1software.png';
import service4 from '../img/1digital.png';
import service5 from '../img/API.png';

const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const banners = [
    { src: banner1, alt: 'First slide' },
    { src: banner2, alt: 'Second slide' },

    // Add more banners as needed
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000, // Interval for automatic sliding
  };

  const cards2 = [
    {
      image: image11,
      title: 'Cool Cotton',
      description: '₹999',
    },
    {
      image: image21,
      title: 'Posh Palette',
      description: '₹999',
    },
    {
      image: image31,
      title: 'Ethnique',
      description: '₹999',
    },
    {
      image: image41,
      title: 'Indigo Chronicles',
      description: '₹999',
    },
    {
      image: image51,
      title: 'Classic Staples',
      description: '₹999',
    },
    {
      image: image61,
      title: 'Office Oomph',
      description: '₹999',
    },
    {
      image: image71,
      title: 'Office Oomph',
      description: '₹999',
    },

  ];

  const cards3 = [
    {
      image: image111,
      title: 'Cool Cotton',
      description: '₹999',
    },

    {
      image: image311,
      title: 'Ethnique',
      description: '₹999',
    },
    {
      image: image411,
      title: 'Indigo Chronicles',
      description: '₹999',
    },

    {
      image: image611,
      title: 'Office Oomph',
      description: '₹999',
    },


  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % banners.length);
    }, 5000); // Interval for automatic sliding

    return () => clearInterval(interval);
  }, [banners.length]);

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, left: '-30px', zIndex: '1', display: 'block' }}
        onClick={onClick}
      />
    );
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, right: '-30px', zIndex: '1', display: 'block' }}
        onClick={onClick}
      />
    );
  };

  const settingsCards2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show 4 cards in a row
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  const settingsCards3 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show 3 cards in a row by default
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024, // breakpoint for desktop screens
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768, // breakpoint for tablet screens
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480, // breakpoint for mobile screens
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const slideStyles = {
    margin: '0 auto', // Center align the slider
    width: '90%', // Adjust width as needed
    position: 'relative', // Ensure relative positioning
  };

  const [clients, setClients] = useState(0);
  const [projects, setProjects] = useState(0);
  const [years, setYears] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      // Update clients if less than 20000
      if (clients < 5000) {
        setClients((prevClients) => {
          const newClients = prevClients + 50;
          return newClients <= 5000 ? newClients : 5000;
        });
      }

      // Update projects if less than 20000
      if (projects < 10000) {
        setProjects((prevProjects) => {
          const newProjects = prevProjects + 50;
          return newProjects <= 10000 ? newProjects : 10000;
        });
      }

      // Update years if less than 18
      if (years < 18) {
        setYears((prevYears) => {
          const newYears = prevYears + 1;
          return newYears <= 18 ? newYears : 18;
        });
      }
    }, 100);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, []);


  return (
    <>


      <Helmet>
        <title>Arc solutions || Home</title>
        <meta name="description" content="This is a description of the page content." />
        <meta name="keywords" content="React, carousel, example, meta tags" />
        <meta name="author" content="Your Name or Company" />
        <meta property="og:title" content="Your Page Title" />
        <meta property="og:description" content="This is a description of the page content." />
        <meta property="og:image" content="URL_to_your_image" />
        <meta property="og:url" content="Your_page_URL" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Your Page Title" />
        <meta name="twitter:description" content="This is a description of the page content." />
        <meta name="twitter:image" content="URL_to_your_image" />
      </Helmet>

      {/* banner section start */}

      <Carousel
        style={{ width: '100%' }}
        interval={5000} // Adjust interval (in milliseconds) for automatic sliding
        pause={false} // Set to false to disable pause on hover
        nextLabel=""
        prevLabel=""
      >
        {banners.map((banner, index) => (
          <Carousel.Item key={index}>
            <a href="#"><img className="d-block w-100" src={banner.src} alt={banner.alt} /></a>
          </Carousel.Item>
        ))}
      </Carousel>
      {/* banner section end */}
      {/* SIDEBAR SOCIAL ICON START*/}

      <div className="icon-bars">
        <a href="https://www.facebook.com/arcconsultant" target="_blank" className="facebook">
          <FontAwesomeIcon icon={faFacebook} size="2x" color="" />
        </a>
        <a href="#" target="_blank" className="instagram">
          <FontAwesomeIcon icon={faInstagram} size="2x" color="" />
        </a>
        <a href="#" target="_blank" className="linkedin">
          <FontAwesomeIcon icon={faLinkedin} size="2x" color="" />
        </a>
        <a href="#" target="_blank" className="twitter">
          <FontAwesomeIcon icon={faTwitter} size="2x" color="" />
        </a>
        <a href="#" target="_blank" className="whatsapp">
          <FontAwesomeIcon icon={faWhatsapp} size="2x" color="" />
        </a>
        <a href="#" target="_blank" className="youtube">
          <FontAwesomeIcon icon={faYoutube} size="2x" color="" />
        </a>
      </div>
      {/* sidebar start */}
      <div class="search_btn">
        <a href="https://arcsolutions.supersite2.myorderbox.com/" class="text-white fw-bold text-decoration-none fw-bold search">Search Domain</a>
      </div>

      {/* sidebar end */}
      {/* SIDEBAR SOCIAL ICON end*/}
      {/* About Section start */}
      <div className="flex justify-center">
        <div className="flex flex-col justify-center">
          <div className="flex flex-col md:flex-row max-w-7xl justify-center items-center ">
            <div className="overflow-hidden w-full m-4 shadow-sm flex flex-col md:flex-row justify-center">
              <div className="flex flex-col md:flex-row items-center">
                <div className="md:w-2/3 m-4 text-justify">
                  <div className="font-bold text-blue-900 text-xl m-2 underline">About Us</div>
                  <div className="fs-6 text-black mt-4 m-2">
                    Arc Solutions is a leading IT Solution provider company based in India with a strong focus towards top-of-the-line Web Application Development, E-Commerce portal development, ERP Solutions, Software Development, Website Designing, Dynamic Website Designing, Mobile App Development, SEO/SMO Services, Online Marketing, Web Portal Development. In simple words, we are a one-stop solution center for all your IT needs by using the latest technologies available in the market.<br></br>
                    <button className="text-primary fw-bold mt-2"><Link to="/About" className="text-decoration-none bg-blue-900 text-white p-2 mt-3 read_more">Read More</Link></button>
                  </div>
                </div>
                <div className=" w-full overflow-hidden"> <img src={about} alt=""
                  className="" /> </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* about section end */}

      {/* services section start */}
      <section className="py-10 bg-gradient-to-r from-gray-100 to-gray-200 sm:py-16 lg:py-24">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="max-w-2xl mx-auto text-left sm:text-center">
            <h6 className="text-blue-900 fw-bold text-3xl">Start Creating Today With Our Web Application Development Services</h6>
          </div>
          <div className="  grid grid-cols-1 gap-6 mt-8 sm:mt-12 xl:mt-20 sm:grid-cols-2 lg:grid-cols-3 lg:gap-8 xl:gap-14">
            <div className="services transition duration-300 transform shadow-lg hover:scale-105">
              <div className="py-8 px-9">
                <center><img className="d-block w-50" src={service} alt={service} /></center>
                <p className="text-lg font-bold text-center">App Development</p>
                <p className="mt-6 text-base text-justify">
                  Arc Solutions's professional Android app developers have both the expertise and experience to develop<br></br>
                  <button className="text-primary mt-2 bg-blue-900 text-white"><Link to="/DevelopmentServices" className="text-white text-decoration-none p-2 rounded-full read_more">Read More</Link></button>
                </p>
              </div>
            </div>

            <div className="services transition duration-300 transform shadow-lg hover:scale-105">
              <div className="py-8 px-9">
                <center><img className="d-block w-50" src={service1} alt={service1} /></center>
                <p className="text-lg font-bold">Web Development</p>
                <p className="mt-6 text-base text-justify">
                  At Arc Solutions, our front-end as well as the back-end website programmers are highly trained coding experts...
                  <br />
                  <button className="text-primary mt-2 bg-blue-900 text-white"><Link to="/DevelopmentServices" className="text-white text-decoration-none p-2 rounded-full">Read More</Link></button>
                </p>
              </div>
            </div>

            <div className="services transition duration-300 transform shadow-lg hover:scale-105">
              <div className="py-8 px-9">
                <center><img className="d-block w-50" src={service2} alt={service2} /></center>
                <p className="text-lg font-bold">ECommerce Development</p>
                <p className="mt-6 text-base text-justify">
                  We’ve expanded our expertise from startups to custom eCommerce solutions for large-scale, high-growth businesses...
                  <button className="text-primary mt-2 bg-blue-900 text-white"><Link to="/DevelopmentServices" className="text-white text-decoration-none p-2 rounded-full">Read More</Link></button>
                </p>
              </div>
            </div>

            <div className="services transition duration-300 transform shadow-lg hover:scale-105">
              <div className="py-8 px-9">
                <center><img className="d-block w-50" src={service3} alt={service3} /></center>
                <p className="text-lg font-bold">Software Development</p>
                <p className="mt-6 text-base text-justify">
                  The customized services offered by Arc Solutions will help you build apps that are a good fit for your existing..
                  <br />
                  <button className="text-primary mt-2 bg-blue-900 text-white"><Link to="/DevelopmentServices" className="text-white text-decoration-none p-2 rounded-full">Read More</Link></button>
                </p>
              </div>
            </div>

            <div className="services transition duration-300 transform shadow-lg hover:scale-105">
              <div className="py-8 px-9">
                <center><img className="d-block w-50" src={service4} alt={service4} /></center>
                <p className="text-lg font-bold">Digital Marketing</p>
                <p className="mt-6 text-base text-justify">
                  Our primary aim is delivering the best insights and strategies for our customers to enhance the sales...
                  <br />
                  <button className="text-primary mt-2 bg-blue-900 text-white"><Link to="/DevelopmentServices" className="text-white text-decoration-none p-2 rounded-full">Read More</Link></button>
                </p>
              </div>
            </div>

            <div className="services transition duration-300 transform shadow-lg hover:scale-105">
              <div className="py-8 px-9">
                <center><img className="d-block w-50" src={service5} alt={service5} /></center>
                <p className="text-lg font-bold">API Development</p>
                <p className="mt-1 text-500 text-justify">
                  Our custom web application development company ensures seamless communication and integration. Our API..
                  <br />
                  <button className="text-primary mt-2 bg-blue-900 text-white"><Link to="/DevelopmentServices" className="text-white text-decoration-none p-2 rounded-full">Read More</Link></button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* services section end */}

      {/* whats & chat Button */}
      <div class="Phone_btn">
        <a href="tel:/9258089306">
          <div class="Phone_icon"><center><img className="d-block w-50" src={phone} alt={phone} /></center></div>
        </a>
        <p class="text_icon text-orange-500 fw-bold">Call Us</p>
      </div>
      <div class="Whatsapp_btn">
        <a target="_blank" href="https://wa.me/9258089306">
          <div class="Whatsapp_icon"><center><img className="d-block w-50" src={whatsapp} alt={whatsapp} /></center></div>
        </a>
        <p class="text_icon text-orange-500 fw-bold">Talk to us?</p>
      </div>

      {/* whats & chat Button */}
      {/* platform section start */}
      <section className="">
        <div className="container mt-2">
          <div className="row align-items-center">
            <h3 className="text-center underline text-blue-900 fw-bold">PLATFORMS USE BY OUR COMPANY</h3>
            <div className="col-md-5 col-12 mt-5">
              <p className="text-justify"> We specialize in delivering high-quality, end-to-end software solutions that align perfectly with the business objectives of our clients. Our methodical software development process ensures a comprehensive approach from initial concept through to final deployment and beyond. We begin by thoroughly understanding our clients' needs and aspirations, conducting in-depth analysis to define project requirements accurately. This meticulous planning phase allows us to design solutions that are not only scalable and secure but also modular and adaptable to future innovations. Our development team is proficient in utilizing the latest technologies and frameworks, ensuring that each solution is crafted with precision and creativity to reflect the unique vision and goals of our clients.
              </p>

            </div>
            <div className="col-md-7 col-12 mt-5 ">
              <div className="row">
                <div className="col-md-4 col-4">
                  <img src={card_1} alt=""
                    className="transition duration-300 hover:scale-105" />
                </div>
                <div className="col-md-4 col-4">
                  <img src={card_2} alt=""
                    className="transition duration-300 hover:scale-105" />
                </div>
                <div className="col-md-4 col-4">
                  <img src={card_3} alt=""
                    className="transition duration-300 hover:scale-105" />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-4 col-4">
                  <img src={card_4} alt="" className="transition duration-300  hover:scale-105" />
                </div>
                <div className="col-md-4 col-4">
                  <img src={card_5} alt="" className="transition duration-300 hover:scale-105" />
                </div>
                <div className="col-md-4 col-4">
                  <img src={card_6} alt="" className="transition duration-300  hover:scale-105" />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-4 col-4">
                  <img src={card_7} alt="" className="transition duration-300  hover:scale-105" />
                </div>
                <div className="col-md-4 col-4">
                  <img src={card_8} alt="" className="transition duration-300 hover:scale-105" />
                </div>
                <div className="col-md-4 col-4">
                  <img src={card_9} alt="" className="transition duration-300  hover:scale-105" />
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-md-4 col-4">
                  <img src={card_10} alt="" className="transition duration-300  hover:scale-105" />
                </div>
                <div className="col-md-4 col-4">
                  <img src={card_11} alt="" className="transition duration-300 hover:scale-105" />
                </div>
                <div className="col-md-4 col-4">
                  <img src={card_12} alt="" className="transition duration-300  hover:scale-105" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* platform section end */}
      {/* contact section start */}
      <section className="bg-gradient-to-b from-blue-900 via-blue-800 to-blue-900">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-12">
              <h3 className="text-white fw-bold">Unlock Your Growth Potential!</h3>
              <p className="text-white fw-bold">Let's break down complex IT issues into actionable solutions you can understand.</p>
              <button className=""><a href="/Contact" className="text-white font-bold bg-orange-500 mt-5 p-2 rounded-lg text-decoration-none">Get Started</a></button>
            </div>
            <div className="col-lg-6 col-12 mt-3">
              <div className="row">
                <div className="col-lg-4 col-4">
                  <div className="card rounded-md transition duration-300 transform shadow-lg hover:scale-105">
                    <h5 className="text-center mt-1">{clients}<span>+</span></h5>
                    <p className="text-center">Clients</p>
                  </div>
                </div>
                <div className="col-lg-4 col-4">
                  <div className="card rounded-md transition duration-300 transform shadow-lg hover:scale-105">
                    <h5 className="text-center mt-1">{projects}<span>+</span></h5>
                    <p className="text-center">Projects</p>
                  </div>
                </div>
                <div className="col-lg-4 col-4">
                  <div className="card rounded-md transition duration-300 transform shadow-lg hover:scale-105">
                    <h5 className="text-center mt-1">{years}<span>+</span></h5>
                    <p className="text-center">Years</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* contact section end */}
      {/* Our portfolio section start */}
      <div className="carousel-container" style={slideStyles}>
        <div className="flex flex-col text-center w-full mb-6">
          <h1 className="underline sm:text-3xl text-2xl font-medium title-font text-gray-900">
            OUR PORTFOLIO
          </h1>
        </div>
        <Slider {...settingsCards3}>
          {cards3.map((card, index) => (
            <div key={index} className="portfolio-card">
              <Link to="">
                <div className="portfolio-card-inner">
                  <img src={card.image} alt={card.title} className="portfolio-image" />
                </div>
              </Link>
            </div>
          ))}
        </Slider>
        <div className="flex justify-center mt-4">
          <Link to='/Portfolio' className="bg-blue-900 hover:bg-orange-500 text-white py-2 px-4 rounded-lg focus:outline-none text-decoration-none">
            View More
          </Link>
        </div>
      </div>
      {/* Our portfolio section End */}
      <style jsx>{`
  .portfolio-card {
    margin-bottom: 20px; /* Adjust as needed */
    width: 100%; /* Ensure each card takes full width */
    max-width: 300px; /* Limit card width if necessary */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for card effect */
    border-radius: 8px; /* Optional: Add rounded corners */
    overflow: hidden; /* Ensure images don't overflow */
  }
  
  .portfolio-card-inner {
    padding: 10px; /* Optional: Add padding inside the card */
  }
  .portfolio-image {
    width: 100%; /* Ensure images take full width inside the card */
    height: auto; /* Maintain aspect ratio */
    display: block; /* Ensure proper image rendering */
  }
`}</style>
      {/* Our Contact section start */}
      <section className="bg-gradient-to-b from-orange-500 via-orange-500 to-orange-500 mt-5">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="col-span-1 md:col-span-1 text-white">
              <h2 className="font-bold text-3xl">Please Share Your Requirements</h2>
              <p className="mt-3 text-white font-bold">We are an eCommerce development agency dedicated to delivering innovative and results-oriented solutions.</p>
              <h5 className="text-white font-bold">1. Fill up your details</h5>
              <p className="text-white text-base">Your data is 100% confidential.</p>
              <h5 className="text-white font-bold">2. What's next?</h5>
              <p className="text-white text-base mb-5">We will connect with you to discuss your requirements.</p>
              <p>
                <span className="h5 fw-bold">Sales:</span>&nbsp; &nbsp;
                <i className="fas fa-phone-alt"></i>&nbsp;<span className="fw-bold">+91-9219666443</span>
              </p>
              <p>
                <span className="h5 fw-bold">Support:</span>&nbsp; &nbsp;
                <i className="fas fa-phone-alt"></i>&nbsp;<span className="fw-bold">0135-4083251</span>
              </p>
              <p>
                <span className="h5 fw-bold">Email:</span>&nbsp; &nbsp;
                <i className="fas fa-envelope"></i>&nbsp;<span className="fw-bold">info@arcsolutions.org</span>
              </p>
            </div>
            <div className="col-span-1 md:col-span-1">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <form action="" method="post">
                  <div className="mb-4">
                    <label htmlFor="name" className="block text-sm font-bold text-gray-700">Name</label>
                    <input id="name" type="text" name="Name" className="form-input mt-1 block w-full rounded-lg border-black-300" placeholder="Enter Your Name" />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="email" className="block text-sm font-bold text-gray-700">Email</label>
                    <input id="email" type="email" name="Email" className="form-input mt-1 block w-full rounded-lg border-black-300" placeholder="Enter Your Email" />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="number" className="block text-sm font-bold text-gray-700">Phone Number</label>
                    <input id="phone" type="number" name="phone" className="form-input mt-1 block w-full rounded-lg border-black-300" placeholder="Enter Your Phone Number" />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="message" className="block text-sm font-bold text-gray-700">Message</label>
                    <textarea id="message" name="Message" rows="4" className="form-textarea mt-1 block w-full rounded-lg border-black-300" placeholder="Message"></textarea>
                  </div>
                  <button type="submit" className="bg-blue-900 text-white py-2 px-4 rounded-lg hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-blue-500">Submit</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section >
      {/* Our Contact section end */}
      {/* Our clients section start */}
      <div className="carousel-container" style={slideStyles}>
        <div className="flex flex-col text-center w-full mb-6">
          <h1 className="underline sm:text-3xl text-2xl font-medium title-font text-gray-900">
            OUR Clients
          </h1>
        </div>
        <Slider {...settingsCards3}>
          {cards2.map((card, index) => (
            <div key={index} className="portfolio-card">

              <div className="portfolio-card-inner">
                <img src={card.image} alt={card.title} className="portfolio-image w-100" />
              </div>

            </div>
          ))}
        </Slider>
      </div>
      {/* our clients end */}
      < Footer />
    </>
  );
};
export default Home;
