import React, { useState } from "react";
import emailjs from 'emailjs-com';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faPhone, faEnvelope, faGlobe, faChartLine, faHeadset, faPhoneVolume } from '@fortawesome/free-solid-svg-icons';
import { Footer } from "../component/Footer";
import { Helmet } from "react-helmet-async"; // Import Helmet
function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: ""
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.send(
      'service_6py4vpd', 
      'template_02epr4j', 
      formData, 
      'jiX0u9JU87_kJ5H7L'
    )
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      alert('Message sent successfully!');
      setFormData({ name: "", phone: "", email: "", message: "" });
    }, (error) => {
      console.log('FAILED...', error);
      alert('Failed to send the message, please try again later.');
    });
  };

  return (
    <div>
      <Helmet>
        <title>Arc Solutions || Contact</title>
        <meta name="description" content="Learn more about Arc Solutions, a leading IT Solution provider." />
        <meta name="keywords" content="Arc Solutions, IT services, Web Development, ERP Solutions, Software Development" />
        <meta name="author" content="Arc Solutions" />
      </Helmet>
      <div className="grid">
        <div className="bg-orange-500 h-72 flex items-center justify-center">
          <div className="flex flex-col max-w-4xl items-center justify-center">
            <div className="text-white text-4xl font-bold">Contact Us</div>
            <div className="m-2 text-white">
              Contact us by filling this contact form.
            </div>
          </div>
        </div>
        <div className="bg-white h-auto flex justify-center">
          <form 
            className="h-auto bg-white -mt-20 w-[80%] md:w-1/2 text-stone-700 font-medium shadow-lg"
            onSubmit={handleSubmit}
          >
            <div className="m-4 font-bold">Enter Your Name</div>
            <input
              type="text" 
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter Your Name"
              className="border-2 ml-4 border-gray-400 h-10 w-3/4 text-gray-400 rounded" required
            />
            <div className="m-4 font-bold">Phone Number</div>
            <input
              type="text" 
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Enter Your Phone"
              className="border-2 ml-4 border-gray-400 h-10 w-3/4 text-gray-400 rounded" required
            />
            <div className="m-4 font-bold">Email</div>
            <input
              type="email" 
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter Your Email"
              className="border-2 ml-4 border-gray-400 h-10 w-3/4 text-gray-400 rounded" required
            />
            <div className="m-4 font-bold">How can we help?</div>
            <textarea 
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="border-2 ml-4 border-gray-400 h-20 w-3/4 text-gray-400 rounded"
            />
            <button 
              type="submit"
              className="bg-blue-900 p-2 m-4 text-white w-max font-normal hover:bg-orange-500 cursor-pointer"
            >
              SEND
            </button>
          </form>
        </div>
      </div>

      <div className="bg-white py-6 sm:py-8 lg:py-12">
        <div className="mx-auto max-w-screen-xl px-4 md:px-8">
          <div className="grid gap-4 sm:grid-cols-2 md:gap-8">
            <div className="rounded-lg bg-blue-900 p-5 hover:bg-orange-500">
              <div className="mb-4 flex items-center justify-between gap-4 border-b pb-4">
                <h3 className="font-semibold text-white sm:text-lg md:text-xl">
                  Development Office / Regd. Office :
                </h3>
              </div>
              <p className="text-gray-500">
                <FontAwesomeIcon icon={faLocationDot} className="text-dark-500" /> &nbsp;
                <span className="text-white">
                  E-7, Nehru Colony Dehradun - 268001 Uttarakhand India
                </span>
              </p>
              <hr />
              <p className="text-gray-500">
                <FontAwesomeIcon icon={faPhone} /> &nbsp;
                <span className="text-white">
                0135-4083251, +91-7351290002
                </span>
              </p>
              <hr />
              <p className="text-gray-500">
                <FontAwesomeIcon icon={faEnvelope} /> &nbsp;
                <span className="text-white">
                  info@arcsolutions.org
                </span>
              </p>
              <hr />
              <p className="text-gray-500">
                <FontAwesomeIcon icon={faGlobe} /> &nbsp;
                <span className="text-white">
                  www.arcsolutions.org
                </span>
              </p>
            </div>

            <div className="rounded-lg bg-blue-900 hover:bg-orange-500 p-5 shadow-lg">
              <div className="mb-4 border-b pb-2">
                <h3 className="font-semibold text-white sm:text-lg md:text-xl flex items-center gap-2">
                  <FontAwesomeIcon icon={faChartLine} className="text-white" /> Sales
                </h3>
              </div>

              <div className="text-white space-y-2">
                <p className="flex items-center">
                  <FontAwesomeIcon icon={faPhoneVolume} className="text-white" /> &nbsp;
                  <span className="text-white">+91-9219666443</span>
                </p>
                <hr className="border-gray-600" />
                <p className="flex items-center">
                  <FontAwesomeIcon icon={faEnvelope} className="text-white" /> &nbsp;
                  <span className="text-white">
                    sales@arcconsultant.com
                  </span>
                </p>
              </div>

              <div className="mt-6 mb-4 border-b pb-2">
                <h3 className="font-semibold text-dark-500 sm:text-lg md:text-xl flex items-center gap-2">
                  <FontAwesomeIcon icon={faHeadset} className="text-white" /> Support
                </h3>
              </div>

              <div className="text-gray-500 space-y-2">
                <p className="flex items-center">
                  <FontAwesomeIcon icon={faPhoneVolume} className="text-white" /> &nbsp;
                  <span className="text-white">+91-9258089306</span>
                </p>
                <hr className="border-gray-600" />
                <p className="flex items-center">
                  <FontAwesomeIcon icon={faEnvelope} className="text-white" /> &nbsp;
                  <span className="text-white">
                    tech@arcsolutions.asia
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Contact;
