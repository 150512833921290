import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Footer } from "./Footer";
import { Helmet } from "react-helmet-async"; // Import Helmet
// Additional images for cards3
import digitalMarketing from "../img/digitalMarketing.png";
import LinkedIn1 from "../img/bulksms.png";
import LinkedIn2 from "../img/sms.png";


const Instagramlikes = () => {
    return (
        <>
         <Helmet>
        <title>Arc Solutions || Instagram Likes</title>
        <meta name="description" content="Learn more about Arc Solutions, a leading IT Solution provider." />
        <meta name="keywords" content="Arc Solutions, IT services, Web Development, ERP Solutions, Software Development" />
        <meta name="author" content="Arc Solutions" />
      </Helmet>
            <div className="bg-orange-500 h-60 flex items-center justify-center">
                <div className="flex flex-col max-w-4xl items-center justify-center">
                    <div className="text-white text-4xl font-bold">Instagram likes</div><br></br>
                    <a href="/" class="text-white text-decoration-none  hover:decoration-4">Home/Instagram likes</a>
                    <div className="text-white">

                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-7">
                        <h3>Leading Service Instagram Likes Providers</h3>
                        <p>
                            At Our Company, we understand the importance of connecting with your audience where they are most active. Our bulk Instagram messaging services provide a powerful and cost-effective solution to engage your audience, notify your staff, and promote your products or services. With billions of active users, Instagram Messenger offers a unique opportunity to reach a vast audience instantly and effectively. Our platform allows you to send personalized messages, ensuring each communication feels direct and meaningful. By leveraging Instagram's extensive reach, you can enhance customer engagement, drive more conversions, and build stronger relationships. Whether you're announcing a new product, sending timely updates, or engaging with customers through interactive content, our bulk messaging service makes it easy to manage and deliver your messages. Plus, with advanced scheduling and detailed reporting, you can optimize your campaigns for the best results. Experience the convenience and impact of reaching your audience on a platform they already use and trust, with the support and reliability of our company.</p>

                    </div>
                    <div className="col-md-5">
                        <img
                            src={LinkedIn2}
                            className="w-75"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <h5>Why Choose Us</h5>
                        Reliable API Integration
                        Seamlessly integrate our powerful Instagram Messaging API into your existing systems. Automate and streamline your messaging processes with ease, enhancing operational efficiency.

                        Intuitive Web Platform
                        Our user-friendly web platform is designed for everyone, from tech-savvy marketers to small business owners. Manage your campaigns, schedule messages, and analyze results with just a few clicks.

                        Personalized Messaging
                        Stand out by delivering personalized messages. Tailor your content with recipient names and other dynamic fields to create a more impactful connection.

                        Advanced Scheduling
                        Schedule your messages to be sent at the optimal time for your audience, ensuring maximum engagement and effectiveness.

                        Detailed Delivery Reports
                        Monitor your campaigns with comprehensive delivery reports. Track success rates, identify trends, and optimize your strategies based on real-time data.

                        Interactive Two-Way Messaging
                        Enable two-way communication with your audience. Receive responses directly and engage in meaningful conversations to build stronger relationships.

                        Robust Contact Management
                        Organize and segment your contact lists effortlessly. Import, export, and manage your contacts to ensure your messages reach the right people.
                    </div>
                </div>

            </div>
            <Footer />
        </>
    );
};

export default Instagramlikes;
